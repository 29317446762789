const initialState = {};

export const FilterReducers = (state = initialState,
  { type= '', payload = {} }) => {
  switch(type){
  case 'DEALER_CATEGORY_SUCCESS':
    return {
      ...state,
      DealerIndustries: payload
    };
  case 'DEALER_BY_INDUSTRY_SUCCESS': // not covered
    return {
      ...state,
      DealerByIndustriesData: payload
    };
  case 'FILTER_DATA_LENGTH':
    return {
      ...state,
      setFilterLength: payload
    };
  case 'PRODUCT_GROUPS_SUCCESS':
    return {
      ...state,
      ProductGroups: payload
    };
  case 'PRODUCT_GROUPS_EMPTY':
    return {
      ...state,
      ProductGroups: {}
    };
  case 'SELECTED_EQUIPMENT':
    return {
      ...state,
      SelectedEquipment: payload
    };
  case 'SET_APPLY_FLAG':
    return {
      ...state,
      isApply: payload
    };
  case 'SET_SELECTED_DATA_PARAM':
    return {
      ...state,
      selectedDataPram: payload
    };
  case 'SELECT_ALL_DATA_PARAM':
    return {
      ...state,
      selectedDataPram: payload
    };
   
  default: return state;
  }
};


