import React from 'react';
import { store } from '../../';
import { string, shape, number, array, bool } from 'prop-types';
import { DealerListSvg } from '../SvgIcons';
export const Dealer = (props) => {  
 
 
  const {
    dealer: {
      locationId = '',
      latitude=0,
      longitude=0,
      locationName = '',
      dealerAccountType= null,
      partIndicator = false,
      serviceIndicator = false,
      cnpjNumber = '',
      accountLegalName = '',
      sellIndicator = false,
      distance = 0,
      uom = '',
      contactDetail: {
        phone = '',
        website = null,
      } = {},
      formattedAddress = [],
    } = {},
    translate: {
      GDL_SALE = '',
      GDL_PART = '',
      GDL_SERVICE = '',
      GDL_AWAY = '',
      GDL_DIRECTIONS = 'Directions',
      GDL_PURCHASE_PARTS = 'Purchase Parts',
      GDL_CALL = 'Call',
      GDL_WEBSITE = 'Website',
      GDL_LEGAL_NAME = 'Legal Name',
      GDL_CNPJ_NUMBER = 'CNPJ',
      GDL_CONTACT_ACCOUNTS_MESSAGE = 'There are no dealers or distributors for the industry or product that you selected. For additional information, contact:'
    } = {},
    common={}
  } = props;
  let jdPartsURL = locationId ? 'https://partscatalog.deere.com/jdrc/?dealer-id='+locationId.substring(1)+'&locale='+common.locale
    :'https://partscatalog.deere.com/';

  let addressNew='';
  formattedAddress.map((address) => {
    addressNew=addressNew+" "+address;
  });
  let userslatlng = common.userslatlng;
  addressNew=encodeURIComponent(addressNew);

 let selectedLocation = common.selectedLocation;

  let directionUrl;
  if (common.BaiduLocale == true){
    directionUrl=common.locationDetected?`http://api.map.baidu.com/direction?origin=latlng:${userslatlng.lat},${userslatlng.long}|name:origin&destination=latlng:${latitude},${longitude}|name:${addressNew}&mode=driving&region=none&output=html`: '';
  } else{
    directionUrl=common.locationDetected?"https://www.google.com/maps/dir/?api=1&origin="+common.currentlatlng.lat+","+common.currentlatlng.long+"&destination="+props.dealer.latitude+","+props.dealer.longitude:"https://maps.google.com?daddr="+addressNew;
  }
  

  directionUrl=directionUrl.trim();
  let greenIconSvg = DealerListSvg(props.dealerNumber)

 
  return (
    
  
    <div className="list-wrap-background">
     
     {
       (dealerAccountType != null) ? <span><p className='span-contact'>{GDL_CONTACT_ACCOUNTS_MESSAGE}</p></span>:""
     }
    
      <div
        key={locationId}
        className={dealerAccountType == "Contact" ? "list-wrap remove-margin contact-account" : dealerAccountType == "Distributor" ? "list-wrap remove-margin distributor-account" : "list-wrap remove-margin"}
        data-test="list-wrap"
      >
        <div className="dealer-name" onClick={ () => dealerListPinHighlighter(props.dealerNumber)}>
          <div className="location-details">
          {greenIconSvg} <h5> {locationName} </h5>
          </div>
          <div className="sales-services" onClick={ () => dealerListPinHighlighter(props.dealerNumber)}>
            <span className="s-small">
              {
                partIndicator === "true" ? GDL_PART : ''
              } &nbsp;
              {
                serviceIndicator === "true" ? GDL_SERVICE : ''
              } &nbsp;
              {
                sellIndicator === "true" ? GDL_SALE : ''
              } &nbsp;&nbsp; {
                distance != null ? "~"+parseFloat(distance).toFixed(2) : ''

              } {uom != null ?uom.toLowerCase() + " "+GDL_AWAY:""}
            </span>
          </div>
        </div>
        <div className="module-name" onClick={ () => dealerListPinHighlighter(props.dealerNumber)}>
          <address>
            {
              formattedAddress.map((address, i) => {
                return <div key={i} className="address">
                  {address}
                </div>;
              })
            }
          </address>
        </div>
        {(store.getState().common.countryCode =="BR") ? 
          <div className='cnpjandlegalname'>
            <div>
              <span className='cnpj-legalname'>{GDL_CNPJ_NUMBER}:&nbsp;</span>
              <span>{cnpjNumber}</span>
            </div>
            <div> 
              <span className='cnpj-legalname'>{GDL_LEGAL_NAME}:&nbsp;</span>
              <span>{accountLegalName}</span>
            </div>  
          </div> :
          null }
        <div
          className={`addressDetails ${common.isMobile ? 'add-margin' : ''}`}
        >
          {website !== null ?
            <div className="color-green right">
              <a href={website.toLowerCase().startsWith("http://") || website.toLowerCase().startsWith("https://") ? website : "http://" + website} target={"_blank"}>
                <span className="font-icon">
                  <svg
                    focusable="false"
                    aria-hidden="true"
                    className="svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 
                        0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83
                        9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                    >
                    </path>
                  </svg>
                </span>
                <span className="cw-des" data-test="cw-des">
                  {
                    website

                  }
                
                </span>
                
                <span className="cw-mob" data-test="cw-mob">
                  {website !== null
                    ? GDL_WEBSITE : ''
                  }
 
                </span>
              </a>
            </div>
            : null
          }
          <div
            className="color-green color-grey"
          >
            <span className="font-icon">
            <a href={"tel:"+phone}>
              <svg
                focusable="false"
                aria-hidden="true"
                className="svg-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 0 0-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"></path></svg>
                </a>
            </span>
            <span
              className="cw-des">
              <a href={"tel:"+phone}>{phone}</a>
            </span> 
            <span className="cw-mob"><a href={"tel:"+phone}>{GDL_CALL}</a> </span>
          </div>
          <div className="color-green left">
            {/* <a href="javascript:void(0)" className="direction" data-test="direction"
              onClick={
                ()=>{setDirectionOnMap({ slat:props.common.userslatlng.lat, slng: props.common.userslatlng.long, lat:latitude,lng:longitude });} 
              }> */}
            <a onClick={()=>{
               if(window.adobeDataLayer != undefined){
                window.adobeDataLayer.push({
                  "event": "Dealer Contacted",
                  "consent": window.OnetrustActiveGroups, 
                  "locationId" : locationId,
                  "latitude":latitude,
                  "longitude":longitude,
                  "locationName" :locationName,
                  "partIndicator" :partIndicator,
                  "serviceIndicator" :serviceIndicator,
                  "sellIndicator" :sellIndicator ,
                  "distance":distance,
                  "contactDetail": {
                    "phone" :phone,
                    "website":website,
                  },
                  formattedAddress:formattedAddress
                  });
                 }
            }} href={directionUrl.trim()}
              rel="noopener noreferrer"
              target="_blank"
              className="direction"
              data-test="direction"
            >
              <span className="font-icon">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  className="svg-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path d="M21.71 11.29l-9-9a.996.996 0 0
                  0-1.41 0l-9 9a.996.996 0 0 
                  0 0 1.41l9 9c.39.39 1.02.39 1.41
                  0l9-9a.996.996 0 0 0 0-1.41zM14 
                  14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 
                  3.5-3.5 3.5z">
                  </path>
                  <path d="M0 0h24v24H0z" fill="none">
                  </path>
                </svg>
              </span>{GDL_DIRECTIONS} 
            </a>
          </div>
          {(store.getState().common.countryCode =="US") ? 
          <div className="color-green left">
            <a href={jdPartsURL.toLowerCase().startsWith("http://") || jdPartsURL.toLowerCase().startsWith("https://") ? jdPartsURL : "http://" + jdPartsURL} target={"_blank"}
            >
              <span className="font-icon">
              <svg focusable="false" aria-hidden="true" fill="#367C2B" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none">
                    </path>
                    <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z">
                    </path>
              </svg>
              </span>
              <span className="cw-des" data-test="cw-des">
                  {
                    GDL_PURCHASE_PARTS
                  }
                </span>
                <span className="cw-mob" data-test="cw-mob">
                  {jdPartsURL !== null
                    ? GDL_PURCHASE_PARTS : ''
                  }
                </span>
            </a>
          </div>: null } 
        </div>         
      </div>
    </div>
  );
};

const dealerListPinHighlighter = (number) =>{
  window.markers.map((marker, i) => { 
      if(marker.index+1 === number){
      new google.maps.event.trigger(marker, 'mouseover')
      new google.maps.event.trigger(marker, 'click')
      window.map.setCenter({ lat: marker.position.lat(), lng: marker.position.lng() });
      window.map.setZoom(10);
    }
  })
}

Dealer.propTypes ={
  dealer: shape({
    locationId: number,
    latitude: number,
    longitude: number,
    locationName: string,
    partIndicator: bool,
    serviceIndicator: bool,
    sellIndicator: bool,
    distance: number,
    uom: string,
    contactDetails: shape({
      phone: string,
      website: string,
    }),
    formattedAddress: array,
  }),
  translate: shape({
    GDL_SALE: string,
    GDL_PART: string,
    GDL_SERVICE: string,
    GDL_AWAY: string,
    GDL_DIRECTIONS: string,
    GDL_CALL: string,
    GDL_WEBSITE: string,
    MEASUREMENT_UNIT: string,
    GDL_LEGAL_NAME: string,
    GDL_CNPJ_NUMBER: string,
    GDL_CONTACT_ACCOUNTS_MESSAGE: string,
    GDL_PURCHASE_PARTS: string
  }),
  common:shape()
};
