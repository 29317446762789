export const MAX_MOBILE_WIDTH = 576;

export const COUNTRIES = new Array(
                                  "AF",
                                  "AL",
                                  "DZ",
                                  "AS",
                                  "AD",
                                  "AO",
                                  "AI",
                                  "AQ",
                                  "AG",
                                  "AR",
                                  "AM",
                                  "AW",
                                  "AU",
                                  "AT",
                                  "AZ",
                                  "BS",
                                  "BH",
                                  "BD",
                                  "BB",
                                  "BY",
                                  "BE",
                                  "BZ",
                                  "BJ",
                                  "BM",
                                  "BT",
                                  "BO",
                                  "BQ",
                                  "BA",
                                  "BW",
                                  "BV",
                                  "BR",
                                  "IO",
                                  "BN",
                                  "BG",
                                  "BF",
                                  "BI",
                                  "CV",
                                  "KH",
                                  "CM",
                                  "CA",
                                  "KY",
                                  "CF",
                                  "TD",
                                  "CL",
                                  "CN",
                                  "CX",
                                  "CC",
                                  "CO",
                                  "KM",
                                  "CD",
                                  "CG",
                                  "CK",
                                  "CR",
                                  "HR",
                                  "CU",
                                  "CW",
                                  "CY",
                                  "CZ",
                                  "CI",
                                  "DK",
                                  "DJ",
                                  "DM",
                                  "DO",
                                  "EC",
                                  "EG",
                                  "SV",
                                  "GQ",
                                  "ER",
                                  "EE",
                                  "SZ",
                                  "ET",
                                  "FK",
                                  "FO",
                                  "FJ",
                                  "FI",
                                  "FR",
                                  "GF",
                                  "PF",
                                  "TF",
                                  "GA",
                                  "GM",
                                  "GE",
                                  "DE",
                                  "GH",
                                  "GI",
                                  "GR",
                                  "GL",
                                  "GD",
                                  "GP",
                                  "GU",
                                  "GT",
                                  "GG",
                                  "GN",
                                  "GW",
                                  "GY",
                                  "HT",
                                  "HM",
                                  "VA",
                                  "HN",
                                  "HK",
                                  "HU",
                                  "IS",
                                  "IN",
                                  "ID",
                                  "IR",
                                  "IQ",
                                  "IE",
                                  "IM",
                                  "IL",
                                  "IT",
                                  "JM",
                                  "JP",
                                  "JE",
                                  "JO",
                                  "KZ",
                                  "KE",
                                  "KI",
                                  "KP",
                                  "KR",
                                  "KW",
                                  "KG",
                                  "LA",
                                  "LV",
                                  "LB",
                                  "LS",
                                  "LR",
                                  "LY",
                                  "LI",
                                  "LT",
                                  "LU",
                                  "MO",
                                  "MG",
                                  "MW",
                                  "MY",
                                  "MV",
                                  "ML",
                                  "MT",
                                  "MH",
                                  "MQ",
                                  "MR",
                                  "MU",
                                  "YT",
                                  "MX",
                                  "FM",
                                  "MD",
                                  "MC",
                                  "MN",
                                  "ME",
                                  "MS",
                                  "MA",
                                  "MZ",
                                  "MM",
                                  "NA",
                                  "NR",
                                  "NP",
                                  "NL",
                                  "NC",
                                  "NZ",
                                  "NI",
                                  "NE",
                                  "NG",
                                  "NU",
                                  "NF",
                                  "MP",
                                  "NO",
                                  "OM",
                                  "PK",
                                  "PW",
                                  "PS",
                                  "PA",
                                  "PG",
                                  "PY",
                                  "PE",
                                  "PH",
                                  "PN",
                                  "PL",
                                  "PT",
                                  "PR",
                                  "QA",
                                  "MK",
                                  "RO",
                                  "RU",
                                  "RW",
                                  "RE",
                                  "BL",
                                  "SH",
                                  "KN",
                                  "LC",
                                  "MF",
                                  "PM",
                                  "VC",
                                  "WS",
                                  "SM",
                                  "ST",
                                  "SA",
                                  "SN",
                                  "RS",
                                  "SC",
                                  "SL",
                                  "SG",
                                  "SX",
                                  "SK",
                                  "SI",
                                  "SB",
                                  "SO",
                                  "ZA",
                                  "GS",
                                  "SS",
                                  "ES",
                                  "LK",
                                  "SD",
                                  "SR",
                                  "SJ",
                                  "SE",
                                  "CH",
                                  "SY",
                                  "TW",
                                  "TJ",
                                  "TZ",
                                  "TH",
                                  "TL",
                                  "TG",
                                  "TK",
                                  "TO",
                                  "TT",
                                  "TN",
                                  "TR",
                                  "TM",
                                  "TC",
                                  "TV",
                                  "UG",
                                  "UA",
                                  "AE",
                                  "GB",
                                  "UM",
                                  "US",
                                  "UY",
                                  "UZ",
                                  "VU",
                                  "VE",
                                  "VN",
                                  "VG",
                                  "VI",
                                  "WF",
                                  "EH",
                                  "YE",
                                  "ZM",
                                  "ZW",
                                  "AX"
                                  );
export const LOCALES = new Array(
                             "bg_BG",
                             "cs_CZ",
                             "da_DK",
                             "de_DE",
                             "de_AT",
                             "el_GR",
                             "en_CA",
                             "en_GB",
                             "en_US",
                             "en_TH",
                             "es_ES",
                             "es_MX",
                             "et_EE",
                             "fi_FI",
                             "fr_CA",
                             "fr_FR",
                             "hr_HR",
                             "hu_HU",
                             "it_IT",
                             "hi_IN",
                             "zh",
                             "zh_TW",
                             "ko_KR",
                             "lt_LT",
                             "lv_LV",
                             "nl_NL",
                             "no_NO",
                             "pl_PL",
                             "pt_BR",
                             "pt_PT",
                             "ro_RO",
                             "ru_RU",
                             "sk_SK",
                             "sl_SI",
                             "sr_RS",
                             "sv_SE",
                             "th_TH",
                             "tr_TR",
                             "zh_CN",
                             "en_AU",
                             "en_NZ",
                             "ka_GE",
	                         "te_IN",
	                         "ta_IN",
		                     "kn_IN",
	                         "bn_IN",
		                     "ja_JP",
	                         "he_IL",
	                         "nl_BE"
                            );
export const LOCALE_MAPPING = {
        'bg': 'bg_BG',
    	'hr': 'hr_HR',
    	'cs': 'cs_CZ',
    	'da': 'da_DK',
    	'nl': 'nl_NL',
    	'et': 'et_EE',
    	'fi': 'fi_FI',
    	'fr': 'fr_FR',
    	'de': 'de_DE',
    	'hu': 'hu_HU',
    	'it': 'it_IT',
        'ko': 'ko_KR',
    	'lv': 'lv_LV',
    	'lt': 'lt_LT',
    	'no': 'no_NO',
    	'pl': 'pl_PL',
    	'pt': 'pt_PT',
    	'ru': 'ru_RU',
    	'sk': 'sk_SK',
    	'es': 'es_ES',
    	'sv': 'sv_SE',
    	'th': 'th_TH',
    	'tr': 'tr_TR',
        "zh": 'zh_CN',
        "hi" :'hi_IN',
	    "he" : 'he_IL'
}
