import React from 'react';
import { func, array, string, shape, bool } from 'prop-types';
import { RadioButton } from '@deere/ux.uxframe-react';
import { store } from '..';
import { Container } from '@deere/ux.uxframe-react/lib/components/Container';


class ViewResultFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkBoxValue: [] || undefined,
      industryIds: '',
      allIndustriesSelected: false,
      isApply: true,
      handleViewResultApplyClik: false,
      industryName: ''
    };
  }
 

  componentDidMount() {
    const {
      getProductGroupsAction = () => { },
      DealerCategoryAction = () => { },
      common: {
        countryCode = '',
        locale = '',
        industriesObj: {
          id = 0
        } = {}
      } = {},
    } = this.props;
    if(locale.includes('he')) {
    
      document.querySelector('.filter-icon').style.transform="rotateY(180deg)";

    }

    const brand = 'johndeere';
    const dataParam = {
      countryCode,
      locale,
      brand
    };
   // DealerCategoryAction(dataParam);
   // getProductGroupsAction(dataParam);

    const elements = document.querySelectorAll('.industries-radio-btn input');
    elements.forEach(el => {
      if (el.id == id) {
        el.checked = true;
        this.setState({
          industryIds: id,
        });
      }
    });

  }



  handleCheckChange = (event, CheckID, name) => {
    const {
      common: {
        countryCode = '',
        locale = '',
        currentlatlng: {
          lat = 0,
          long = 0,
        } = {},
      } = {},
      DealersByIndustryAction = () => { },
      setApplyFlag = () => { },
      setSelectedDataParam = () => { },
      setDealerCategoryIndustries = () => { },
      setDeepLinkChange = () => { }
    } = this.props;


    const {
      currentTarget: {
        checked = false,
      } = {},
    } = event;
    const {
      checkBoxValue
    } = this.state;

    let industriesObj = {
      name,
      id: CheckID
    };

    if (checked === true) {
      setDeepLinkChange(true)
      this.setState({
        industryIds: CheckID,
        checkBoxValue: [...checkBoxValue, checked],
        industryName: name
      });
    }
    
   
    let SelectedEquipmentID = 0;
    let SelectedEquipmentName = '';

    const brand = 'johndeere';
    let AllIndtriesElement = false;
    const dataParam = {
      "industryId": CheckID,
      "countryCode": countryCode,
      "brand": brand,
      "equipmentName": SelectedEquipmentName,
      "equipmentId": SelectedEquipmentID,
      "locale": locale,
      "allIndustriesSelected": AllIndtriesElement,
      "latitude": lat,
      "longitude": long,
      "radius": "0 MI"
    };
    const selectedParam = {
      "industryIds": CheckID,
      "allIndustriesSelected": AllIndtriesElement,
    };    
    setDealerCategoryIndustries(industriesObj);
    setSelectedDataParam(selectedParam);
    DealersByIndustryAction(dataParam,false);
    setApplyFlag(false);

  }

 

  handleCancel = () => {
    const {
      setApplyFlag = () => { },
    } = this.props;
    setApplyFlag(false);
  }

  render() {
    const {
      industries = [],
      common = {},
      setFeedbackSubmitted = () => {},
      translate: {
        GDL_APPLY = '',
        GDL_CANCEL = '',
        GDL_VIEW_RESULTS = '',
        GDL_DEALER_CATEGORY = '',
        GDL_SELECT_A_PRODUCT = ''
      } = {},
    } = this.props;
    return (
      <div className="View-result-outer-wrapper">
        <div className="fixed-panel">
          <div className="row">
            <div className="col-15">
              <h3 className="margin-top-bottom">{GDL_DEALER_CATEGORY}</h3>
            </div>
            <div className=
              {`col-9 ${!common.currentlatlng.lat ? "hidden" : ""}`}
            >
              <div className={`filters-count`} data-test="filters-count">
                <div
                  className="row view-result"
                  data-test="view-result"
                  onClick={this.handleCancel}
                  >
                  <div className="filter-label">
                    <span className="label">
                      {GDL_VIEW_RESULTS}
                    </span>
                  </div>
                  <div className="filter-icon">
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      fill="#367c2b"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                      >
                      </path>
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-dealer-list">
     
          <ul className={
            `${industries.length > 2 ?'add-overflow' : ''}`
          }>
            {
              industries.map(({ name = '', industryProductGroupId = '', productGroup = '' }, index) => {
                return (
                  <>
                  {industryProductGroupId == 3 
                  ? productGroup.map(({ id = '' , name = ''}, i) => 
                  <li key={id}>
                    <RadioButton
                      label={name}
                      id={id}
                      onChange={
                        (event) => this.handleCheckChange(event, id, name)
                      }
                      value={name}
                      className="industries-radio-btn"
                      data-test={`industries-radio-btn-${index}`}
                      name={"radio"}
                    />
                  </li>
                  )
                  :
                  <li key={industryProductGroupId}>
                    <RadioButton
                      label={name}
                      id={industryProductGroupId}
                      onChange={
                        (event) => this.handleCheckChange(event, industryProductGroupId, name)
                      }
                      value={name}
                      className="industries-radio-btn"
                      data-test={`industries-radio-btn-${index}`}
                      name={"radio"}
                    />
                  </li>
              }
                  </>
                );
              })
            }
          </ul>
          <div className="button-section">
            <button
              className="btn cancel btn-secondary"
              type="button"
              data-test="cancel"
              onClick={this.handleCancel}
            >
              {GDL_CANCEL}
            </button>

          </div> 
          <Container>
          </Container>
        </div> 
      </div>
    );
  }
}

ViewResultFilters.propTypes = {

  handleFindFilterClick: func,
  getProductGroupsAction: func,
  industries: array,
  DealerCategoryAction: func,
  common: shape({
    countryCode: string,
    locale: string
  }),
  DealersByIndustryAction: func,
  setApplyFlag: func,
  setFeedbackSubmitted: func,
  setDeepLinkChange: func,
  setSelectedDataParam: func,
  selectALLDataParam: func,
  isFilterViewFlag: bool,
  translate: shape({
    GDL_APPLY: string,
    GDL_CALL: string,
    GDL_CANCEL: string,
    GDL_EQUIPMENT: string,
    GDL_VIEW_RESULTS: string,
    GDL_DEALER_CATEGORY: string,
  }),
  setDealerCategoryIndustries: func
};
export default ViewResultFilters;