import React, { useState } from 'react';
import { func, string, shape, bool } from 'prop-types';
import {
  Button,
  Modal,
  InputGroup, Search, Form
} from '@deere/ux.uxframe-react';
import GoogleFilters from '../../Shared/GoogleFilters/';
import ProductSearch from '../../Shared/ProductSearch/';

export const handleClick = (e,props) => {
  const {
    landing_page_action = () => { },
    dealer_category_popup_action = () => { },
  } = props;
  landing_page_action(false);
  dealer_category_popup_action(true);
};

export const handleFindDealerClick = (e,props,count) => {
  const {
    common: {
      currentlatlng: {
        lat = 0,
        long = 0
      } = {},
      locale = '',
      countryCode = '',
      brand = '',
    } = {},
    SelectedEquipment,
    landing_page_action = () => { },
    dealersListPanelDispalyAction = () => { },
    DealersByIndustryAction = () => { },
  } = props;

  // const {
  //   industryId = 0,
  // } = this.state;
  const dataParam = {
    "industryId": null,
    "countryCode": countryCode,
    "brand": brand,
    "equipmentName": SelectedEquipment.name,
    "equipmentId": SelectedEquipment.id,
    "locale": locale,
    "allIndustriesSelected": false,
    "latitude": lat,
    "longitude": long,
    "radius": "0 MI"
  };
  landing_page_action(false);
  dealersListPanelDispalyAction(true);
  DealersByIndustryAction(dataParam,false);
}


export const LandingPageModal = (props) => {
  const {
    handleClose = () => { },
    translate: {
      GDL_FIND_A_DEALER_NEAR_YOU = '',
      GDL_FIND_DEALERS = '',
      GDL_FIND_YOUR_EQUIPMENT = '',
      GDL_YOUR_LOCATION = '',
      GDL_ENTER_A_PRODUCT_EX_MOWER = '',
      GDL_NOT_FINDING_MESSEGE = '',
      GDL_CHOOSE_A_DEALER_CATEGORY = ''
    } = {},
    PopUpData: {
      landingPageFlag = false,
    }= {},  

  } = props;


  return (
    <Modal show={landingPageFlag}
      className="find-a-dealer" data-test="find-a-dealer">
      <Modal.Header>
        <h2 className="dealer-title">
          {GDL_FIND_A_DEALER_NEAR_YOU} 
        </h2>
      </Modal.Header>

      <Modal.Body>
        <GoogleFilters {...props} name="google-page-level-search1" />
      </Modal.Body>

      <h3 className="equipment">{GDL_FIND_YOUR_EQUIPMENT}</h3>
      <Modal.Body className="select-equipment">

        <Form role="search">
          <ProductSearch {...props} hidelabel={true}
              />
        </Form>
        <p>{GDL_NOT_FINDING_MESSEGE}&nbsp;
          <a onClick={(e) =>handleClick(e, props)} href="javascript:void(0)">
            {GDL_CHOOSE_A_DEALER_CATEGORY}
          </a>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button  className={`${props.SelectedEquipment.id ? '' : 'disabled'}`} variant="primary" onClick={(e) =>handleFindDealerClick(e, props)}>
          {GDL_FIND_DEALERS}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



LandingPageModal.propTypes = {
  handleClose: func,
  translate: shape({
    GDL_FIND_A_DEALER_NEAR_YOU: string,
    GDL_FIND_DEALERS: string,
    GDL_FIND_YOUR_EQUIPMENT: string,
    GDL_YOUR_LOCATION: string,
    GDL_ENTER_A_PRODUCT_EX_MOWER: string,
    GDL_NOT_FINDING_MESSEGE: string,
    GDL_EX_MOWER: string
  }),

  common: shape({
    landingPageFlag: bool
  }),
  PopUpData: shape({
    landingPageFlag: bool
  }),
  landing_page_action: func,
  dealer_category_popup_action: func,
  DealersByIndustryAction: func,
};

export default LandingPageModal;