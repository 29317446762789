import { array, bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { COUNTRIES, LOCALE_MAPPING } from '../Shared/Constants/AppConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { store } from '..';
import BaiduMap from '../BaiduMap/';
import FindADealerList from '../FindADealerList/';
import MapWrapper from '../MapDisplay/MapWrapper';
import {
  onLandingPageAddStyle
} from '../Shared';
//import FeedbackModal from '../Shared/FeedbackModal';
import FooterComponent from '../Shared/Footer/';
import HeaderComponent from '../Shared/Header/';
import ViewResultFilters from '../ViewResultFilters/';
import {
  DealerCategoryAction, DealersByIndustryAction, setDeepLinkChange,DealersByIndustryAction1, getProductGroupsAction, selectALLDataParam, selectEquipment, setApplyFlag, setFilterDataLength, setSelectedDataParam
} from '../ViewResultFilters/action';
import {
  DealerDetailsAction,
  getLocalAction,
  setMeasumentUnit,
  setCurrentLatLng,
  setUsersLatLng,
  getCountrycodeAction,
  setCountryCode,
  setLocale,
  invalid_address_error_message_action,
  set_isPlaceChanged_true,
  set_isPlaceChanged_false,
  toggle_noroute_found,
  getGeocoderBaiduMap,
  landing_page_action,
  dealer_category_popup_action,
  dealersListPanelDispalyAction,
  setBrand,
  setDealerCategoryIndustries,
  getAddressFromGeoCoder
} from '../Wrapper/action';

let productGroupName = ""
let isProductGroupAvailable = true
let indusId;
let currentThis;

export class DealerPage extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      gmap: props.common.isMobile ? false : true,
      isFilterView: false,
      show: false
    };

  }
 componentDidMount(){

   //cookie - one-trust script START
   if(!document.querySelector('script[src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"]')){
    var script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    script.setAttribute('data-document-language','true');
    script.setAttribute('charset','UTF-8');
    script.setAttribute('data-domain-script','fe5f4e7e-b09c-4bd3-b416-74872baf3c55');
    document.head.appendChild(script);
  }
   //cookie - one-trust script END
   
  const {
    dealersListPanelDispalyAction = () => { }
  } = this.props;
   if(this.props.common.locale.includes('he')) {
     document.body.style.direction= "rtl";
     document.getElementsByClassName('uxf-footer-legal')[0].style.float = "right";
     document.getElementsByClassName('footer-list')[0].style.float = "left";
   }else {
     document.body.style.direction= "ltr";
   }
  dealersListPanelDispalyAction(true)
  if (navigator && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      this.successCallback, this.errorCallback,
    );

  }

 }
 errorCallback =() =>{
   let currentLatLng ={
     lat :0,
     long : 0
   }
   const{
    DealersByIndustryAction = () => { },
   } = this.props;
   let countryCode
      if(this.props.match.params.country){
         countryCode = this.props.match.params.country
      }
      setCountryCode(countryCode)
    let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale;
    let brand="Johndeere";
  let language = navigator.language.split("-");
  if(!store.getState().common.BaiduLocale) {
    this.loadMapjs(language[0], language[1], currentLatLng, true,true);
  } else {
    //this.BAIDU_LOADED(language[0], language[1], currentLatLng, true,true);
  }
  let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';

   
  let dataParam1 = {
    "industryId": this.props.deeplinkProductGroup ? this.props.deeplinkProductGroup: this.props.deeplinkIndustry,
    "countryCode": countryCode,
    "brand": brand,
    "equipmentName": "SelectedEquipmentName",
    "equipmentId": 7,
    "locale":deeplinkLocale != undefined ? deeplinkLocale :   navigator.language && navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping ,
    "allIndustriesSelected": false,
    "latitude": this.props.common.currentlatlng.lat,
    "longitude": this.props.common.currentlatlng.long,
    "radius": "0 MI"
  };
DealersByIndustryAction(dataParam1,true)
 }

 successCallback = (position) => {
  const {
    DealerCategoryAction = () => { },
    DealersByIndustryAction = () => { },
    dealersListPanelDispalyAction = () => { },
    
    industries = [],
    getCountrycodeAction =() => { },
    setCountryCode = () =>{ },
    
  } = this.props;
  currentThis = this
  var currentLatLng =
    { lat: position.coords.latitude, long: position.coords.longitude };
    if(!(this.props.common.currentlatlng.lat && this.props.common.currentlatlng.long) ){
  this.props.setCurrentLatLng(currentLatLng);
    }
    if(this.props.deeplinkProductGroup || this.props.deeplinkIndustry){
      dealersListPanelDispalyAction(true);
      getAddressFromGeoCoder(currentLatLng.lat, currentLatLng.long, store.getState().common.BaiduLocale).then( response => {
        const address = response;
        if(document.getElementById("google-page-level-search3")){
          if(this.props.common.selectedLocation != undefined && this.props.common.selectedLocation.length > 0){
            document.getElementById("google-page-level-search3").value = this.props.common.selectedLocation
            
          }else{
            document.getElementById("google-page-level-search3").value = address;
           store.dispatch({ type: 'PLACE_CHANGE_TRUE', payload: address });
          } 
        }
          
        
      });
      let countryCode
      if(this.props.match.params.country){
         countryCode = this.props.match.params.country
      }
      setCountryCode(countryCode)
    let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale;
    let brand="Johndeere";
   
  
    let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';

  
    let dataParam = {
      countryCode,
      locale:deeplinkLocale != undefined ? deeplinkLocale : navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping,
      brand
    };
  
    DealerCategoryAction(dataParam).then(() => {
      const {
        industries = [],
        setDealerCategoryIndustries = () => { }
      } = currentThis.props;
     productGroupName = ""
     isProductGroupAvailable = true
       indusId = this.props.deeplinkProductGroup ? this.props.deeplinkProductGroup : this.props.deeplinkIndustry;
       if (industries && industries.length > 0) {
         if (indusId) {
           for(let i = 0 ; i< industries.length ;i++){
             if (industries[i].industryProductGroupId == indusId) {
               productGroupName = industries[i].name
             }
           }
   
           if(productGroupName == '') {
             for(let i = 0 ; i< industries.length ;i++){
               for(let j = 0 ; j< industries[i].productGroup.length ;j++){
                 if(industries[i].productGroup[j].id == indusId){
                   productGroupName = industries[i].productGroup[j].name
                 }
               }
             }
           }
           let industriesObj = {
             name : productGroupName,
             id: indusId,
           };
           setDealerCategoryIndustries(industriesObj)
           if(productGroupName == '')
           isProductGroupAvailable = false;
       }
       }
       let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';


      let dataParam1 = {
        "industryId": this.props.deeplinkProductGroup ? this.props.deeplinkProductGroup: this.props.deeplinkIndustry,
        "countryCode": countryCode,
        "brand": brand,
        "equipmentName": "SelectedEquipmentName",
        "equipmentId": 7,
        "locale":deeplinkLocale != undefined ? deeplinkLocale : navigator.language && navigator.language.includes("-") ? navigator.language.replace("-", "_") :  localeFromMapping,
        "allIndustriesSelected": false,
        "latitude": this.props.common.currentlatlng.lat,
        "longitude": this.props.common.currentlatlng.long,
        "radius": "0 MI"
      };
    DealersByIndustryAction(dataParam1,true)
    });
    
    
    }
  }
  componentDidUpdate(prevProps) {
    const {
      industries = [],
      
    } = this.props;
    let language = navigator.language.split("-");
    if(!store.getState().common.BaiduLocale) {
      this.loadMapjs(language[0], language[1], this.props.common.currentlatlng, true,true);
    } else {
      //this.BAIDU_LOADED(language[0], language[1], this.props.common.currentlatlng, true,true);
    }
    onLandingPageAddStyle()
  }

  GetGmapState = (props) => {
    this.setState({ gmap: props });
    setTimeout(() => window.onResize(), 2000);
  }


  render() {
    const {
      dealers = [],
      industries = [],
      common = {},
      translate = {},
      isApplyFlag = false
    } = this.props;
    const {
      gmap
    } = this.state;
    const {
      setDealerCategoryIndustries = () => { }

    } = this.props;
  
    let ListParam = {
      gmap: this.state.gmap,
      GetGmapState: this.GetGmapState,
      productGroupName: productGroupName,
      isProductGroupAvailable : isProductGroupAvailable
    }

    return (
      <div>
        <HeaderComponent />
        <div className="container-fluid main-container">
          <div className="row">
            <div
              id="left-container"
              className={`col-xl-7 ${this.props.dealersListPanelFlag === false ?
                'display-none' : ''}`}
            >
              <div className="outer-wrapper" data-test="outer-wrapper">{
                isApplyFlag !== true 
                  ?
                  <FindADealerList {...(this.props)} {...ListParam} />
                  :
                  <ViewResultFilters {...(this.props)} {...ListParam} />

              }
              </div>

            </div>

            <div
              className={common.isMobile && gmap === false ?
                'col-xl-17 resetmarginpadding hidden' :
                'col-xl-17 resetmarginpadding show'}
            >

              <div
                className={
                  `alert alert-danger ${common.noRouteFound ? "" : "hidden"}`
                }
                role="alert"
              >
                Sorry, we could not calculate directions
                from your location to selected dealer.
                <button
                  type="button" className="close"
                  data-test="close"
                  data-dismiss="alert" aria-label="Close"
                  onClick={toggle_noroute_found}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {
                common.BaiduLocale == true ?
                  <BaiduMap
                    setBaiduMap={obj => this.BAIDU_LOADED = obj}
                    dealers={dealers}
                    common={common}
                    searchP={obj => this.searchcallback = obj}
                    fetchNearestDealers={this.fetchNearestDealers}
                  />
                  :
                  <MapWrapper
                    setMap={obj => this.loadMapjs = obj}
                    searchP={obj => this.searchcallback = obj}
                    dealers={dealers}
                    fetchNearestDealers={this.fetchNearestDealers}
                    common={common}
                    setCurrentLatLng={setCurrentLatLng}
                    set_isPlaceChanged_true={set_isPlaceChanged_true}
                    set_isPlaceChanged_false={set_isPlaceChanged_false}
                    invalid_address_error_message_action={
                      this.props.invalid_address_error_message_action
                    }
                    AllProps={this.props}
                    translate={translate}
                  />

              }

            </div>
          </div>
          <div
            className={`mobile ${common.isMobile && gmap === true ?
              'show row' : 'hidden'}`}
            data-test="mobile"
          >
            <div className="dealer-list">
              <div id="list-wrap-wrapper">
                <div></div>
              </div>
            </div>

          </div>
        </div>

        {/* {!store.getState().common.ratingSubmitted ? <FeedbackModal locale={common.locale}/> : ''} */}
        <FooterComponent />
      </div>

    );
  }
}

DealerPage.propTypes = {
  getLocalAction: func,
  setCurrentLatLng: func,
  setDeepLinkChange: func,
  setMeasumentUnit: func,
  DealerDetailsAction: func,
  getCountrycodeAction: func,
  setUsersLatLng: func,
  enableGo: func,
  disableGo: func,
  setLocale: func,
  setFilterLength: number,
  translate: shape({
    GDL_FIND_A_DEALER: string,
    GDL_LIST: string,
    MEASUREMENT_UNIT: string,
    GDL_DIRECTIONS: string
  }),
  dealers: array,
  setCountryCode: func,
  common: shape(),
  set_isPlaceChanged_true: func,
  set_isPlaceChanged_false: func,
  invalid_address_error_message_action: func,
  toggle_noroute_found: func,
  industries: array,
  productgroups: array,
  isApplyFlag: bool,
  SelectedEquipment: shape(),
  selectedDataPram: shape({}),
  DealerCategoryAction: func,
  getProductGroupsAction: func,
  DealersByIndustryAction: func,
  setFilterDataLength: func,
  setApplyFlag: func,
  selectEquipment: func,
  setSelectedDataParam: func,
  selectALLDataParam: func,
  getGeocoderBaiduMap: func,
  landing_page_action: func,
  dealer_category_popup_action: func,
  DealersByIndustryAction1: func,
  DealerByIndustriesData: shape({}),
  dealersListPanelDispalyAction: func,
  PopUpData: shape(),
  setBrand: func,
  setDealerCategoryIndustries: func,

};



const mapStateToProps = state => ({
  dealers: state.dealerList.DealerList,
  translate: state.translate,
  common: state.common,
  PopUpData: state.PopUpData,
  setFilterLength: state.Filterdata.setFilterLength,
  DealerByIndustriesData: state.Filterdata.DealerByIndustriesData,
  industries: state.Filterdata.DealerIndustries,
  productgroups: state.Filterdata.ProductGroups,
  isApplyFlag: state.Filterdata.isApply,
  SelectedEquipment: state.Filterdata.SelectedEquipment,
  selectedDataPram: state.Filterdata.selectedDataPram,
});


const mapDispatchToProps = dispatch => bindActionCreators({
  DealerDetailsAction,
  setDeepLinkChange,
  getLocalAction,
  setMeasumentUnit,
  setCurrentLatLng,
  setUsersLatLng,
  getCountrycodeAction,
  setCountryCode,
  setLocale,
  set_isPlaceChanged_true,
  set_isPlaceChanged_false,
  invalid_address_error_message_action,
  toggle_noroute_found,
  // --view
  DealerCategoryAction,
  getProductGroupsAction,
  DealersByIndustryAction,
  setFilterDataLength,
  setApplyFlag,
  selectEquipment,
  setSelectedDataParam,
  selectALLDataParam,
  getGeocoderBaiduMap,
  // setBaiduLocaleFlag,
  landing_page_action,
  dealer_category_popup_action,
  DealersByIndustryAction1,
  dealersListPanelDispalyAction,
  setBrand,
  setDealerCategoryIndustries
},
  dispatch
);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerPage));


