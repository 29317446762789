import React from 'react';
import CardFlip from '../CardFlip';
import { withRouter } from 'react-router-dom';
// iconURL
const DealerCategoryIndustries = ({
  history = this.props.history,
  countryCode = '',
  industries = [],
  handleIndustriesClick = () => { }
}) =>
  industries.map(({iconURL = '', name = '', industryProductGroupId='', productGroup='' }, i) =>
    <div
      className={`col-4 list-${i}`}
      key={industryProductGroupId}
      test-data={`list-${i}`}
      onClick={(e) => handleIndustriesClick(e, name, industryProductGroupId)}
      id={industryProductGroupId}
    >
{industryProductGroupId == 3 ? <CardFlip history={history} iconURL={iconURL} name={name} productGroup={productGroup} countryCode={countryCode}/> :      <div className="tiles">
        <br/>
        <div className="img-wrap">
          <img src={iconURL} />
        </div>
        <div><span>{name}</span></div>
      </div>
      }
    </div>
  );

export default withRouter(DealerCategoryIndustries);
