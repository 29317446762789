import React from 'react';
import Rotate from '../../assets/images/img/Rotate.png';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDealerCategoryIndustries } from '../../Wrapper/action';
class CardFlip extends React.Component {

  constructor(props) {
    super(props);
  }

  anchorClickHandler = (id, name) => {
  
    const {
      countryCode,
      setDealerCategoryIndustries = () => { }
    } = this.props;
    let industriesObj = {
      name: name,
      id: id,
    };
    setDealerCategoryIndustries(industriesObj);
    this.props.history.push({
      pathname: `/servlet/country=${countryCode}`,
      search: `?industry=${id}`,
      state: { deeplinkIndustry: id }
    });
  }

  render() {
    return (
      <div className="tiles">
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="iconPosition"><img src={Rotate}/></div>
              <br/>
              <div className="img-wrap">
                <img src={this.props.iconURL}/>
              </div>
              <div><span>{this.props.name}</span></div>
            </div>
            <div className="flip-card-back">
              <table>
                <tr>
                  <td>
                    <div className="flipCardBack">
                      { 
                        this.props.productGroup.map(({ id = '', name = '' }, i) => 
                          <a className="flip-card-anchor" onClick={() => this.anchorClickHandler(id, name)}> {name}<br/></a>
                        )
                      }
                    </div>    
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardFlip.propTypes = {
  setDealerCategoryIndustries: func
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setDealerCategoryIndustries
},
dispatch
);

export default connect(
  null,
  mapDispatchToProps
)(CardFlip);