import { store } from '..';
import GreenLocationSVGHover
from '../assets/images/svg/green-location-hover.svg';
// import { landing_page_action } from '../Wrapper/action';
import { DealerCategoryAction, getProductGroupsAction } from '../ViewResultFilters/action';
import { getCountrycodeAction } from '../Wrapper/action';
import { LOCALE_MAPPING } from './Constants/AppConstants';


export const getElementByAttr = (Wrapper, attr) => {
  return Wrapper.find(`[data-test="${attr}"]`);
};

export const getElementByClass = (Wrapper, attr) => {
  return Wrapper.find(attr);
};

export const getElementByAttrEventHandler = (Wrapper, attr) => {
  return Wrapper.find(`[data-gdl-item="${attr}"]`);
};
export const Asyncgdl = (c) => {
  return new Promise(function (resolve, reject) {
    c(resolve, reject);
  });
};


export const setDirectionOnMap = ({ slat = 0, slng = 0, lat = 0, lng = 0 }) => {

  let isMobile = store.getState().common.isMobile;

  if (isMobile) {

    window.open(`https://maps.google.com/maps?q=${lat},${lng}`, "_blank");
  }
  else {
    if (window.directionsDisplay != null) {
      window.directionsDisplay.setMap(null);
      window.directionsDisplay = null;
    }
    window.directionsDisplay = new window.google.maps.DirectionsRenderer;
    var directionsService = new window.google.maps.DirectionsService;

    window.directionsDisplay.setMap(map);
    var start = {
      lat: slat, lng: slng
    };
    var end = { lat: lat, lng: lng };

    directionsService.route({
      origin: start,
      destination: end,
      travelMode: 'DRIVING'
    }, function (response, status) {
      if (status === 'OK') {
        window.directionsDisplay.setDirections(response);
      } else {
        store.dispatch({ type: 'TOGGLE_NO_ROUTE_FOUND' });

        //window.alert('Directions request failed due to ' + status);
      }
    });
  }
};
export const getMeasumentUnit = (countrycode = '') => {
  if (
    countrycode == "LR" || countrycode == "US" ||
    countrycode == "GB" || countrycode == "MM"
  )
    return "mi";
  return "km";
};

export const loadBaiduScript = (loadscript, callback) => {

  // if (document.getElementById("baiduemapgdl")) {
  //   document.getElementById("baiduemapgdl").remove(
  //     document.getElementById("baiduemapgdl")
  //   );
  // }

  // var h = window.document.getElementsByTagName('head')[0] || document.body;
  // var s = window.document.createElement('script');
  // s.type = 'text/javascript';
  // s.id = 'baiduemapgdl';
  // s.src = "https://api.map.baidu.com/api?v=2.0&amp;ak=ue1GDdzWkXW2WlTWkeYQL7zEb1WBBIbU&amp;s=1";
  // BaiduLoaded
  // let googlemaploaded = store.getState().common.googlemaploaded;
  // console.log("googlemaploaded",googlemaploaded);
  // s.onload = () => {    
  //   store.dispatch({ type: 'BAIDU_LOADED' });
  //   callback();        
  // };
  // h.appendChild(s);

  let f = setInterval(() => {
    if (window.BMap) {
      clearInterval(f);
      store.dispatch({ type: 'BAIDU_LOADED' });
      callback();
    }
  }, 2000);
  // let BaiduLoaded = store.getState().common.BaiduLoaded;
  // if (window.BMap) {
  //   store.dispatch({ type: 'BAIDU_LOADED' });
  //   callback();
  // }
  // callback();

};

export const loadGoogleScript = (loadscript, callback) => {
  if (document.getElementById("googlemapgdl")) {
    document.getElementById("googlemapgdl").remove(
      document.getElementById("googlemapgdl")
    );
  }
  let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale; 
    let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';
    let browserLocale =  navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping;
    const locale = deeplinkLocale != undefined ? deeplinkLocale : browserLocale;
  var h = window.document.getElementsByTagName('head')[0] || document.body;
  var s = window.document.createElement('script');

  let language = locale.split("-");
  //let language = local[0], region = local[1];
  s.type = 'text/javascript';
  s.id = 'googlemapgdl';
  s.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyClSN8cZpnl2922PZReSf6Igc-7ucJEDf0&v=3&libraries=places&language=" + language[0] + "&region=" + language[1];
  s.onload = () => {
    callback();

    store.dispatch({ type: 'MAP_LOADED', payload:true });
    //window.loaded = true;
  };
  let googlemaploaded = store.getState().common.googlemaploaded;
  if (!googlemaploaded) {
    h.appendChild(s);
  }
  else {
    // this.initMap(currentLatLng,this.props.dealers);
  }
};

export const initAutoComplete = (inputValue, dealersListPanelFlag) => {
  // console.log(inputValue);
  let input_name= inputValue.id;
  let baidu = store.getState().common.BaiduLocale;
 
  if (!baidu) {
    if (window.google) {
      var input = inputValue;
      var autocomplete = new window.google.maps.places.Autocomplete(input);
      store.dispatch({ type: 'PLACE_CHANGE_FALSE' });
      if (autocomplete && autocomplete.addListener)
        // console.log("autocomplete", autocomplete);
        autocomplete.addListener('place_changed', function () {
          let location = autocomplete.getPlace().geometry.location;
          let { lat, lng } = location;
          var currentLatLng = { lat: lat(), long: lng() };
          let locale = store.getState().common.locale;
          updateSelectedLocation();
          store.dispatch({ type: 'SET_CURRENT_LATLNG', payload:  currentLatLng });
          if(!dealersListPanelFlag) {
            getCountrycodeAction(currentLatLng.lat, currentLatLng.long)(store.dispatch).then((responseData) => {
              let countrycode = responseData;
              store.dispatch({ type: 'COUNTRY_CODE', payload: countrycode });
              let brand="Johndeere";
            
              const dataParam = {
                countryCode:countrycode,
                locale:locale,
                brand
              };
              //  getProductGroupsAction(dataParam)(store.dispatch);
              DealerCategoryAction(dataParam)(store.dispatch);
            });
        
          }
          if(dealersListPanelFlag){
            // this.handleChangeLocations(industryId)
            const query = new URLSearchParams(window.location.search);

            let industryIdFromParams = query.get("productGroup") ? query.get("productGroup") : query.get("industry");
            let industryId = store.getState().common.industriesObj ? store.getState().common.industriesObj.id : industryIdFromParams;
            let industryName = store.getState().common.industriesObj ? store.getState().common.industriesObj.name : "";
            const latitude = location.lat();
            const longitude = location.lng(); 
            GoogleFiltersObj.handleSelectedLocation(industryId, industryName, latitude,longitude,locale);
          }
        });
    }
    
    // window.onResize();
  }
  
  else if (window.BMap) {
    window[input_name] = new window.BMap.Autocomplete(
      {
        "input": input_name,
        "location": window.map
      });
    store.dispatch({ type: 'PLACE_CHANGE_FALSE' });
    window[input_name].addEventListener("onhighlight", (e) => {
      var str = "";
      var _value = e.fromitem.value;
      var value = "";
      if (e.fromitem.index > -1) {
        value = _value.province +
          _value.city + _value.district +
          _value.street + _value.business;
      }
      str = "FromItem<br />index = " +
        e.fromitem.index + "<br />value = "
        + value;

      value = "";
      if (e.toitem.index > -1) {
        _value = e.toitem.value;
        value = _value.province +
          _value.city + _value.district +
          _value.street + _value.business;
      }
      str += "<br />ToItem<br />index = " +
        e.toitem.index + "<br />value = " + value;
      G("searchResultPanel").innerHTML = str;
    });
    var myValue;
    window[input_name].addEventListener("onconfirm", function (e) {
      var _value = e.item.value;
      myValue = _value.province +
        _value.city +
        _value.district +
        _value.street +
        _value.business;
      G("searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
      searchMap(myValue,dealersListPanelFlag);
    });
  }
};

function updateSelectedLocation() {
  if (document.getElementById('google-page-level-search2') != null) {
    store.dispatch({ type: 'PLACE_CHANGE_TRUE', payload: document.getElementById('google-page-level-search2').value });
  }
  else if (document.getElementById('google-page-level-search3') != null) {
    store.dispatch({ type: 'PLACE_CHANGE_TRUE', payload: document.getElementById('google-page-level-search3').value });
  }
}

function searchMap(myValue, dealersListPanelFlag) {
  // var keyCode = e.keyCode;
  var location = myValue;
  var geocoder = new BMap.Geocoder();
  geocoder.getPoint(location, function (results) {
    var point = results ? results : "";
    if (point) {
      // map.panTo(point);
      // setMarker(point);
      // setLocation(location);
      // setLatlng(point);
      //setTimezone(event.latLng);
      let { lat, lng } = point;
      var currentLatLng = { lat: lat, long: lng };
      store.dispatch({ type: 'PLACE_CHANGE_TRUE',payload:'' });
      // store.dispatch({ type: 'SET_CURRENT_LATLNG', payload: currentLatLng });
      let locale = store.getState().common.locale;
      updateSelectedLocation();
      store.dispatch({ type: 'SET_CURRENT_LATLNG', payload:  currentLatLng });
      if(!dealersListPanelFlag) {
        getCountrycodeAction(currentLatLng.lat, currentLatLng.long)(store.dispatch).then((responseData) => {
          let countrycode = responseData;
          store.dispatch({ type: 'COUNTRY_CODE', payload: countrycode });
          let brand="Johndeere";
          
          const dataParam = {
            countryCode:countrycode,
            locale:locale,
            brand
          };
          //  getProductGroupsAction(dataParam)(store.dispatch);
          DealerCategoryAction(dataParam)(store.dispatch);
        });
      
      }
      if(dealersListPanelFlag){
        // this.handleChangeLocations(industryId)
        const query = new URLSearchParams(window.location.search);

        let industryIdFromParams = query.get("productGroup") ? query.get("productGroup") : query.get("industry");
        let industryId = store.getState().common.industriesObj ? store.getState().common.industriesObj.id : industryIdFromParams;
        let industryName = store.getState().common.industriesObj ? store.getState().common.industriesObj.name : "";
        GoogleFiltersObj.handleSelectedLocation(industryId,industryName,lat,lng,locale);
      }
      
    } else {
      console.warn("geocoder is not match!");
    }
  });
}

const G = (id) => {
  return document.getElementById(id);
};


var myValue;
// function setPlace(){
// map.clearOverlays();
// function myFun(){
// var pp = local.getResults().getPoi(0).point;
// console.log("pp", local.getResults());
// let addMarker = new window.BMap.Marker(pp, {   
//   icon: GreenLocationSVGHover,
// });
// window.map.centerAndZoom(pp, 18);
// window.map.addOverlay(addMarker); 
//   }
//   var local = new window.BMap.LocalSearch(window.map, { 
//     onSearchComplete: myFun
//   });
//   local.search(myValue);
// }


// export const BAIDU_LOADED = () => dispatch => {
//   let f = setInterval(() => {
//     if (window.BMap) {
//       clearInterval(f);
//       dispatch({ type: 'BAIDU_LOADED' });

//     }
//   }, 2000);
// };

// BAIDU_LOADED();

export const geocodeAddr = (
  input,
  txt,
  BaiduLocale,
  getGeocoderBaiduMap,
  fetchNearestDealers,
  setCurrentLatLng,
  invalid_address_error_message_action,
  displaydealers
) => {

  // let fetchNearestDealers = this.props.fetchNearestDealers;
  // let setCurrentLatLng = this.props.setCurrentLatLng;
  // let invalid_address_error_message_action = this.props.invalid_address_error_message_action;
  // let baidu = store.getState().common.BaiduLocale;
  // var geocoder;
  if (!BaiduLocale) {
    var geocoder = new window.google.maps.Geocoder();
    // var geocoder;

    // if ( typeof geocoder == 'undefined') geocoder = new google.maps.Geocoder();
    geocoder.geocode({ "address": txt }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        var lat = results[0].geometry.location.lat(),
          lng = results[0].geometry.location.lng(),
          // placeName = results[0].address_components[0].long_name, 
          placeName = results[0].formatted_address,       
          latlng = new window.google.maps.LatLng(lat, lng);
        invalid_address_error_message_action(false);
        var currentLatLng =
          { lat: lat, long: lng };
        setCurrentLatLng(currentLatLng);
        WrapperObj.fetchNearestDealers(currentLatLng,displaydealers,false).then((lang1, lang2) => {
        });
      } else {
        invalid_address_error_message_action(true);

      }
      placeName ? input.value = placeName : null;

    });

    // ----
  }
  else {
    var gc = new BMap.Geocoder();
    gc.getPoint(txt, function(results){
      var point = results;
      if (point) {
        let { lat, lng } = point;
        invalid_address_error_message_action(false);
        var currentLatLng = { lat: lat, long: lng };
        setCurrentLatLng(currentLatLng);
        fetchNearestDealers(currentLatLng, displaydealers);
      } else {
        invalid_address_error_message_action(true);
      }
    });
  }

};

export const getCountry = (results, hfallback) => {
  for (var i = 0; i < results.length; i++) {
    for (var j = 0; j < results[i].address_components.length; j++) {
      if (results[i].address_components[j].types[0] == "country") {
        return checkForIsland(results[i].address_components[j].short_name);
      }
      if (results[i].address_components[j].types.length == 2) {
        if (results[i].address_components[j].types[0] == "political") {
          return checkForIsland(results[i].address_components[j].short_name);
        }        
      }
    }  
  }
  if (hfallback.compound_code &&
    hfallback.compound_code.indexOf("Cyprus") != -1) {
    return "CY";
  }
  return false; 
};

function checkForIsland (shortName) {
  if (shortName === "GG" || shortName === "JE"){
    return "GB";
  } else if (shortName === "NF" || shortName === "CX" ) {
    return "AU";
  }
  else if (shortName === "GU" ) {
    return "US";
  }
  else {
    return shortName;
  }
}

export const goSearch = (props, input, displaydealers) => {
  const {
    common: {
      isPlaceChanged = false,
      BaiduLocale = false,
      //getGeocoderBaiduMap = () => {},
    } = {},
    getGeocoderBaiduMap = () => { },
    fetchNearestDealers = () => { },
    setCurrentLatLng = () => { },
    invalid_address_error_message_action = () => { },
    searchParent = () => { }
  } = props;

  if (!isPlaceChanged) {
    let txt = input && input.value;
    geocodeAddr(input, txt,
      BaiduLocale,
      getGeocoderBaiduMap,
      fetchNearestDealers,
      setCurrentLatLng,
      invalid_address_error_message_action,
      displaydealers
    );
    // getGeocoderBaiduMap("中国");
    // alert("Please Enter valid location");
  }
  else {
    searchParent(displaydealers);
  }
};

export const reset = (inputref) => {

  if (inputref && inputref.current) {
    inputref.current.focus();
    inputref.current.value = '';
  }
  let googleautocompletepopup =
    global.document.querySelector(".pac-container");
  googleautocompletepopup ?
    googleautocompletepopup.style.display = "none" : null;
};

export const onLandingPageAddStyle = () => {
  const WrapperIndex =
    global.document && global.document.getElementById("root");
  var leftContainer = global.document &&
    global.document.getElementById("left-container");
  var mapCanvas =
    global.document && global.document.getElementById("map_canvas");
  if (WrapperIndex) {
    var productSearch = 0;
    productSearch = global.document &&
      global.document.querySelector(".product-search");
    if(productSearch){     
      productSearch = productSearch.offsetHeight;
    }
    let fixedPanel = global.document.querySelector(".fixed-panel");

    let dealerListHeight =
      global.document && global.document.querySelector(".dealer-list");
    let footer = global.document.querySelector(".footer").offsetHeight;
    let header = global.document.querySelector(".header").offsetHeight;
    let fixedFind = fixedPanel ? fixedPanel.offsetHeight :0;
    const clientHeight = window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    let calculatedHeight = footer + fixedFind - productSearch;
    dealerListHeight.style.height =
      clientHeight - calculatedHeight + 'px'; // 407
     
     
  }

};


