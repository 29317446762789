import React from 'react';
import { COUNTRIES, LOCALE_MAPPING } from '../src/Shared/Constants/AppConstants';
import {withRouter} from 'react-router'
import axios from "axios";
import {
  func, string, array, shape, number, bool,
} from 'prop-types';
import HeaderComponent from './Shared/Header/';
import FooterComponent from './Shared/Footer/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getLocalAction,
  setLocale,
  setBrand,
  getCountrycodeAction
} from './Wrapper/action';
import local from './localReducer';
import { store } from '.';
export class App extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    
    
    

  }
  

  componentDidMount() {
    const {
      getLocalAction = () => { },
      setLocale = () => { },
      setBrand = ()=>{},
    } = this.props;
    let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';

    const locale =
      navigator.language && navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping ;
    if(locale.includes('he')) {
      document.body.style.direction= "rtl";
    }else {
      document.body.style.direction= "ltr";
    }
    setLocale(locale);
    const brand = 'johndeere';
    setBrand(brand);
    
    getLocalAction(locale, brand).then((res) => {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.successCallback, this.errorCallback,
        );

      }
    });   
  }
  
  componentDidUpdate(prevProps) {
    const {
      common: {
        countryCode = ''
      } = {},
      DealerCategoryAction = () => { }
    } = this.props;
    if (countryCode && countryCode !== prevProps.common.countryCode) {
      let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';

    const locale =
      navigator.language && navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping ;
      const dataParam = {
        countryCode,
        locale,
        brand: "johndeere"
      };
    }
    
  }

  successCallback = (position) => {
    
    var currentLatLng =
      { lat: position.coords.latitude, long: position.coords.longitude };
    let data = "";
    getCountrycodeAction(currentLatLng.lat, currentLatLng.long)(store.dispatch).then((responseData) => {
      this.props.history.replace({
        pathname: `/servlet/country=${responseData}`,
      });
    });
   
  }

 

  errorCallback = () => {
    
     this.props.history.replace({
        pathname: `/servlet`,
      
      })
    
  }

  render() {
    
    return (
      <div>
        <HeaderComponent/>
               
        <FooterComponent />
      </div>

    );
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  setLocale,
  getLocalAction,
  setBrand
},
  dispatch
  );

App.propTypes = {
  getLocalAction: func,
  setLocale: func,
  setBrand: func
}


export default withRouter(connect(null,
  mapDispatchToProps
)(App));


