import React from 'react';
import { string, shape, number, array, bool } from 'prop-types';
class DealerCardPopUp extends React.Component {
  constructor(props){
    super(props);
  }

render(){
  const {
    translate: {
      GDL_DIRECTIONS = 'Directions',
  
    } = {},
  } = this.props;

  let directionUrl;
  if (this.props.common.BaiduLocale == true){
    directionUrl=this.props.common.locationDetected?`http://api.map.baidu.com/direction?origin=latlng:${this.props.userslatlng.lat},${this.props.userslatlng.long}|name:origin&destination=latlng:${latitude},${longitude}|name:${addressNew}&mode=driving&region=none&output=html`: '';
  } else{
    directionUrl=this.props.common.locationDetected?"https://www.google.com/maps/dir/?api=1&origin="+this.props.common.currentlatlng.lat+","+this.props.common.currentlatlng.long+"&destination="+this.props.dealer.latitude+","+this.props.dealer.longitude:"https://maps.google.com?daddr="+addressNew;
  }
 
  return (
    <div className="list-wrap-background">
      
          <div>
            <h5> {this.props.dealer.locationName} </h5>
          </div>
          <div>
            <address>
              {
                this.props.dealer.formattedAddress.map((address, i) => {
                  return <div key={i}>
                   <h8> {address} </h8>
                  </div>;
                })
              }
            </address>
            <div>
              <span>
                <svg
                  focusable="false"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 0 0-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"></path></svg>
              </span>
              <span>
               <h8> {this.props.dealer.contactDetail.phone}</h8>
              </span> 
            </div>
                      <a href={directionUrl.trim()}
              rel="noopener noreferrer"
              target="_blank"
              className="direction"
              data-test="direction"
            >
              <span className="font-icon">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  className="svg-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path d="M21.71 11.29l-9-9a.996.996 0 0
                  0-1.41 0l-9 9a.996.996 0 0 
                  0 0 1.41l9 9c.39.39 1.02.39 1.41
                  0l9-9a.996.996 0 0 0 0-1.41zM14 
                  14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 
                  3.5-3.5 3.5z">
                  </path>
                  <path d="M0 0h24v24H0z" fill="none">
                  </path>
                </svg>
              </span>{GDL_DIRECTIONS}
            </a></div>
          </div>
  );

}
}

DealerCardPopUp.propTypes ={
    locationId: number,
    latitude: number,
    longitude: number,
    locationName: string,
    partIndicator: bool,
    serviceIndicator: bool,
    sellIndicator: bool,
    distance: number,
    uom: string,
    contactDetails: shape({
      phone: string,
      website: string,
    }),
    formattedAddress: array,
    translate: shape({
      GDL_DIRECTIONS: string
    }),
    common:shape()
 };
export default DealerCardPopUp;
