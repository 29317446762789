import React from 'react';
import { array, shape, func } from 'prop-types';
import GreenLocationSVG from '../assets/images/svg/green-location.svg';
import GreenLocationSVGHover
  from '../assets/images/svg/green-location-hover.svg';
import CurrentLocationSVG from '../assets/images/img/centerpin.png';
import BlackLocationSVG from '../assets/images/svg/black-location.svg';
import BloackLocationSVGHover
  from '../assets/images/svg/black-location-hover.svg';
import { loadBaiduScript } from '../Shared/';
import { landing_page_action } from '../Wrapper/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class BaiduMap extends React.Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    const {
      searchP = () => { },
      setBaiduMap = () => { },
    } = this.props;
    searchP(this.searchDealer);
    setBaiduMap(this.BAIDU_LOADED);
  }

  componentDidUpdate(prevProps) {
    const {
      dealers = [],
      common: {
        BaiduLoaded = false,
        currentlatlng = {},
        dataloaded = false,

      } = {}
    } = this.props;
    // this.initBaiduMap(currentlatlng, dealers, BaiduLoaded);

    // if ( BaiduLoaded) {
    if (dataloaded && dealers !== prevProps.dealers) {
      this.initBaiduMap(currentlatlng, dealers);
    }
  }

  BAIDU_LOADED = (language, region, currentLatLng, loadscript,displaydealers) => {
    loadBaiduScript(loadscript,
      () => {
        let dealer=[];  
        dealer=this.props.dealers;
        this.initBaiduMap(currentLatLng, dealer,displaydealers);
        //  this.initBaiduMap(currentLatLng, this.props.dealers);
      }
    );
  }


  searchDealer = (displaydealers) => {
    var currentLatLng =
      this.props.common.currentlatlng;
    let fetchNearestDealers = this.props.fetchNearestDealers;
    // let invalid_address_error_message_action =
    //this.props.invalid_address_error_message_action;
    // invalid_address_error_message_action(false);
    fetchNearestDealers(currentLatLng,displaydealers);
  }
  // mapInit = (dealers, long, lat, BaiduLoaded) => {
  initBaiduMap = (currentLatLng, dealers, landing_page_action) => {

    const {
      common: {
        BaiduLoaded = false,
      } = {}
    } = this.props;

    // this.props.landing_page_action(true);
    let WBMap = window.BMap;
    // window.BMap = BMap;
    let point = new WBMap.Point(currentLatLng.long, currentLatLng.lat);
    let map = new WBMap.Map("map_canvas");
    map.centerAndZoom(point, 9);
    map.enableScrollWheelZoom(true);

    // -------Add Markers Starts---------
    var centerPinIcon = new WBMap.Icon(CurrentLocationSVG , new WBMap.Size(40, 50), {   
        anchor: new WBMap.Size(12, 27),
    });
    window.markers = [];    
    if(!landing_page_action){
    map.addOverlay(new WBMap.Marker(point, {icon: centerPinIcon}));
    }                     
    dealers.map(({ longitude = '', latitude = '', locationName = '' }, i) => {
      let point = new WBMap.Point(longitude, latitude);
      //73.8217239,18.5205085
      let iconMarker = new WBMap.Icon(GreenLocationSVG,
        new WBMap.Size(30, 34), {
        anchor: new WBMap.Size(12, 27),
      });

      let hoverIconMarker = new WBMap.Icon(GreenLocationSVGHover,
        new WBMap.Size(30, 36), {
        anchor: new WBMap.Size(12, 27),
      });

      // import Black Theme Iconns Added
      let BlackIconMarker = new WBMap.Icon(BlackLocationSVG,
        new WBMap.Size(30, 34), {
        anchor: new WBMap.Size(12, 27),
      });

      let BlackIconHoverIconMarker = new WBMap.Icon(BloackLocationSVGHover,
        new WBMap.Size(30, 36), {
        anchor: new WBMap.Size(12, 27),
      });
      // For Markers uncomments below lines

      var DealerName = new WBMap.Label(locationName,
        { offset: new WBMap.Size(-27, 27) });
      let addMarker = new WBMap.Marker(point, {
        icon: iconMarker,
        label: DealerName,
      });

      DealerName.setStyle({
        color: "#333",
        fontSize: "12px",
        height: "20px",
        lineHeight: "20px",
        fontFamily: "Microsoft Accor",
        border: "none",
        background: "transparent",
        fontWeight: "bold"
      });
       addMarker.index = i;
       map.addOverlay(addMarker);
      addMarker.setLabel(DealerName);
      window.markers.push(addMarker);

      addMarker.addEventListener('click', () => {

        window.markers.map((marker) => {
          marker.setIcon(iconMarker);
          let label = marker.getLabel();
          label.setStyle({
            color: "#333",
          });
        });

        addMarker.setIcon(hoverIconMarker);
        DealerName.setStyle({
          color: "#367C2B",
        });

        let dealerlist =
          document.querySelectorAll(".dealer-list > .list-wrap-background");
        let selecteddealerlist = dealerlist[i];
        selecteddealerlist.className = 'list-wrap-background selected-dealer';
        let selecteddealerlistTop =
          selecteddealerlist.offsetTop - dealerlist[0].offsetTop;
        selecteddealerlist.parentElement.scrollTo({
          top: selecteddealerlistTop,
          left: 100,
          behavior: 'smooth'
        });
        let wrapper = null;
        wrapper = document.querySelector("#list-wrap-wrapper");
        if(selecteddealerlist != undefined) {
        wrapper.innerHTML = selecteddealerlist.outerHTML;
        }
      });
      // -------Add Markers Ends---------
    });
    window.onResize();


  }

  render() {
    return (
      <div
        className="mapwrap" id="mapwrap" data-test="mapwrap">
        <div id="map_canvas" >

        </div>
      </div>
    );
  }
}
// const mapStateToProps = state => ({
//   common: state.common,
// });

// const mapDispatchToProps = dispatch => bindActionCreators({ 
//   landing_page_action,  
// },
// dispatch
// );

BaiduMap.propTypes = {
  dealers: array,
  common: shape(),
  fetchNearestDealers: func,
  searchP: func,
  setBaiduMap: func,
};

export default BaiduMap;
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(BaiduMap);