import React from 'react';
import { store } from '..';
import DealerCardPopUp from './DealerCard/'
import {
  func, array, shape, string
} from 'prop-types';
import GreenLocationSVG from '../assets/images/svg/green-location.svg';
import { setDirectionOnMap, loadGoogleScript } from '../Shared';
import GreenLocationSVGHover
  from '../assets/images/svg/green-location-hover.svg';
import BlackLocationSVG from '../assets/images/svg/black-location.svg';
import BlackLocationSVGHover from
  '../assets/images/svg/black-location-hover.svg';
import CurrentLocationSVG from '../assets/images/img/centerpin.png';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { MarkerNumber, MarkerNumberhover } from '../Shared/SvgIcons';
import Dealer from '../Shared/Dealer';
import ReactDOMServer from 'react-dom/server';
let currentInfoWindow = null;
class MapWrapper extends React.Component {
 
  constructor(props) {
    
    super(props);
  }

  componentDidMount() {
    const {
      setMap = () => { },
      searchP = () => { },
    } = this.props;
    setMap(this.loadMapjs);
    searchP(this.searchDealer);
  }

  checkBoxChecked() {
    return true;
  }

  loadMapjs = (language, region, currentLatLng, loadscript, displaydealers) => {
    const {
      AllProps: {
        PopUpData: {
          dealersListPanelFlag = false
        } = {},

      } = {},
    } = this.props;
    loadGoogleScript(loadscript,
      () => {
        let dealers = [];
        dealers = this.props.dealers;
        if (dealersListPanelFlag == true) {
          this.initMap(currentLatLng, dealers);
        } else {
          this.initialMap(currentLatLng, dealers);
        }

      }
    );
  };

  componentDidUpdate(prevProps) {
    
    const {
      common: {
        currentlatlng = {},
        googlemaploaded = false,
        dataloaded = false
      } = {},
      dealers = [],
      AllProps: {
        PopUpData: {
          dealersListPanelFlag = false
        } = {},

      } = {},
    } = this.props;

    if (dataloaded && googlemaploaded && dealers !== prevProps.dealers) {
      // this.initMap(currentlatlng, dealers);
      if (dealersListPanelFlag == true) {
        this.initMap(currentlatlng, dealers);
      } else {
        this.initialMap(currentlatlng, dealers);
      }

    }
  }
  viewdirection = (e) => {
    // setDirectionOnMap({slat=0, slng=0, lat= 0, lng= 0});
  }

  initialMap = (current_lanlong, dealers) => {
    window.localizer = new window.google.maps.Geocoder();
    if (document.getElementById('map_canvas') && dealers)
      document.getElementById('map_canvas').innerHTML = "";
    window.map = new window.google.maps.Map(
      document.getElementById('map_canvas'), {
      zoom: 3,
      center: {
        lat: parseFloat(current_lanlong.lat),
        lng: parseFloat(current_lanlong.long),
      }
    });

    var myOptions = {
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: window.google.maps.ControlPosition.TOP_LEFT,
        //draggableCursor: ''
      },
      zoomControl: true,
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
        position: window.google.maps.ControlPosition.TOP_LEFT
      },
      mapTypeControl: false,
      scrollwheel: true,
      disableDefaultUI: false,
      scaleControl: true,
      gestureHandling: 'greedy',
      streetViewControl: false,
      overviewMapControl: false,
      panControl: false,
      draggable: true,
      disableDoubleClickZoom: false,
      minZoom: 3,
      zoom: 4,
      center: new window.google.maps.LatLng(
        parseFloat(current_lanlong.lat), parseFloat(current_lanlong.long)
      )
    };

    window.markers = [];
    window.map.setOptions(myOptions);
    window.onResize();
  }

  // -------Initial Map Ends----------


  initMap(current_lanlong, dealers) {
    window.localizer = new window.google.maps.Geocoder();
    if (document.getElementById('map_canvas') && dealers)
      document.getElementById('map_canvas').innerHTML = "";
    window.map = new window.google.maps.Map(
      document.getElementById('map_canvas'), {
      zoom: 2,
      center: {
        lat: parseFloat(current_lanlong.lat),
        lng: parseFloat(current_lanlong.long),
      }
    });
    var myOptions = {
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: window.google.maps.ControlPosition.TOP_LEFT
      },

      zoomControl: true,

      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
        position: window.google.maps.ControlPosition.TOP_LEFT
      },

      mapTypeControl: false,
      scrollwheel: true,
      disableDefaultUI: false,
      scaleControl: true,
      gestureHandling: 'greedy',
      streetViewControl: false,
      overviewMapControl: false,
      panControl: false,
      draggable: true,
      disableDoubleClickZoom: false,
      minZoom: 3,
      zoom: dealers.length < 4 ? 5 : 8,
      center: new window.google.maps.LatLng(
        parseFloat(current_lanlong.lat), parseFloat(current_lanlong.long)
      )
    };

    window.markers = [];
    

    // ----------working-------
    if (dealers.length > 0) {

      let userLocation;
       var currentlatlng = store.getState().common.currentlatlng;
       userLocation = new window.google.maps.Marker({
       position: { lat: currentlatlng.lat, lng: currentlatlng.long } ,
       map: window.map,
       visible: true,
       icon: CurrentLocationSVG,
       zIndex: 1000
        });

      dealers.map((dealer, i) => {
        let uluru = { lat: dealer.latitude, lng: dealer.longitude };
        let encodedGreenIconSvg = MarkerNumber(i + 1);
        let encodedGreenIconSvgHover = MarkerNumberhover();
        let greenMarkerIcon = {
          url: "data:image/svg+xml," + encodedGreenIconSvg,
          //size: new google.maps.Size(14, 20),
          scaledSize: new window.google.maps.Size(40, 40),
          labelOrigin: new window.google.maps.Point(40, 45),
          anchor: new window.google.maps.Point(20, 15),
          // fillColor: "#000",        

        };
        let greenMarkerIconHover = {
          url: GreenLocationSVGHover,
          // size: new google.maps.Size(50, 50),
          scaledSize: new window.google.maps.Size(40, 40),
          labelOrigin: new window.google.maps.Point(40, 45),
          anchor: new window.google.maps.Point(20, 15),
          // fillColor: "#000",        

        };
        let blackMarkerIcon = {
          url: BlackLocationSVG,
          // size: new google.maps.Size(50, 50),
          scaledSize: new window.google.maps.Size(40, 40),
          labelOrigin: new window.google.maps.Point(40, 45),
          anchor: new window.google.maps.Point(20, 15),
          // fillColor: "#000",        

        };
        let blackMarkerIconHover = {
          url: BlackLocationSVGHover,
          // size: new google.maps.Size(50, 50),
          scaledSize: new window.google.maps.Size(40, 40),
          labelOrigin: new window.google.maps.Point(40, 45),
          anchor: new window.google.maps.Point(20, 15),
          // fillColor: "#000",        
        };
        let addMarker;
        // add Markers & labels uncomment this line

        addMarker = new window.google.maps.Marker({
          position: uluru,
          map: window.map,
          visible: true,
          icon: greenMarkerIcon,
          label: {
            text: dealer.locationName,
            className: "label",
            color: '#333',
            fontSize: '12px',
            fontWeight: "bold"
          },
          zIndex: 500
        });

        let mapDisplayDetailsCard = <DealerCardPopUp dealer={dealer} common={this.props.common} translate={this.props.translate}/>;
        
       
        addMarker.index = i;
        window.markers.push(addMarker);
        addMarker.addListener('mouseover', function() {
          if(currentInfoWindow != null){
            currentInfoWindow.close();
          }
          addMarker.infowindow = new window.google.maps.InfoWindow({ content: ReactDOMServer.renderToString(mapDisplayDetailsCard), map: window.map });
          addMarker.infowindow.open(window.map, this);
          currentInfoWindow = addMarker.infowindow
        });
      
        window.map.addListener('click', function() {
          if(currentInfoWindow != null){
          currentInfoWindow.close(window.map, this)
          }
        });

        const marker = window.google.maps.event.addListener(addMarker, 'click', function () {
          let dealerlist = document.querySelectorAll(".dealer-list > .list-wrap-background");
          Array.prototype.map.call(dealerlist, (el) => {
            el.className = 'list-wrap-background';
          });

          let selecteddealerlist = dealerlist[this.index];
          selecteddealerlist.className = "list-wrap-background selected-dealer";
          let selecteddealerlistTop =
            selecteddealerlist.offsetTop - dealerlist[0].offsetTop;
          selecteddealerlist.parentElement.scrollTo({
            top: selecteddealerlistTop,
            left: 100,
            behavior: 'smooth'
          });
          let wrapper = null;
          wrapper = document.querySelector("#list-wrap-wrapper");
          if (selecteddealerlist != undefined) {
            wrapper.innerHTML = selecteddealerlist.outerHTML;
          }
          let directionclick =
            document.querySelector("#list-wrap-wrapper .direction");
          directionclick.addEventListener("click", () => {
            setDirectionOnMap({
              slat: 0, slng: 0, lat: addMarker.position.lat(),
              lng: addMarker.position.lng()
            });
          }, false);

          window.markers.map((marker, i) => {
            let markerindex = marker.index + 1;
            let addmarkerindex = addMarker.index + 1;

            if (markerindex != addmarkerindex) {

              let clickicon = {
                url: "data:image/svg+xml," + MarkerNumber(++i),
                scaledSize: new window.google.maps.Size(40, 40),
                labelOrigin: new window.google.maps.Point(40, 45),
                anchor: new window.google.maps.Point(20, 15),

              };
              marker.setIcon(clickicon);
            }
            else {

              let uclickicon = {
                url: "data:image/svg+xml," + MarkerNumberhover(addmarkerindex),
                //size: new google.maps.Size(14, 20),
                scaledSize: new window.google.maps.Size(40, 40),
                labelOrigin: new window.google.maps.Point(40, 45),
                anchor: new window.google.maps.Point(20, 15),
                // fillColor: "#000",        

              };


              addMarker.setIcon(uclickicon);

            }

            //  marker.setLabel(label);
            //     // marker.infowindow.close(map, marker);
          });




          //  let label = marker.getLabel();
          // label.color = "#333";
          // addMarker.setIcon(greenMarkerIconHover);
          //  let label = this.getLabel();
          //  label.color = "#367C2B";
          //  this.setLabel(label);
        });


      }

      );

      var options = {
        imagePath: '../images/m'
      };

      // add Markers & labels Ends
     new MarkerClusterer(window.map, markers, options);

    }
    
    window.map.setOptions(myOptions);
    // initAutoComplete(current.props);

    // window.gdlAutoCompleteReset = () => {
    //   initAutoComplete(current.props);
    // };

    window.onResize();
  }

  searchDealer = (displaydealers) => {
    var currentLatLng =
      this.props.common.currentlatlng;
    let fetchNearestDealers = this.props.fetchNearestDealers;
    let invalid_address_error_message_action =
      this.props.invalid_address_error_message_action;

    invalid_address_error_message_action(false);
    fetchNearestDealers(currentLatLng, displaydealers);
  }

  render() {
    return (
      <div className="mapwrap" id="mapwrap" data-test="mapwrap">
        <div id="map_canvas" />
      </div>
    );
  }
}
MapWrapper.propTypes = {
  setClick: func,
  fetchNearestDealers: func,
  setCurrentLatLng: func,
  dealers: array,
  common: shape({
    currentlatlng: shape()
  }),
  invalid_address_error_message_action: func,
  set_isPlaceChanged_false: func,
  set_isPlaceChanged_true: func,
  setMap: func,
  searchP: func,
  setBaiduMap: func,
  translate: shape({
    GDL_DIRECTIONS: string
  }),
  AllProps: shape({})
};

export default MapWrapper;
