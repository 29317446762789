/* eslint-disable max-len */

import React from 'react';
import { Header } from '@deere/ux.uxframe-react';
import { store } from '../..';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
 
   render (){
     return(
       <div className="header">
         <Header
           inputProps={{ ariaLabel: 'Search' }}
           search={false}
           mobileMenu={false}
           submitBtnProps={{ ariaLabel: 'Search' }}
           clearBtnProps={{ ariaLabel: 'Clear search' }}
           logoLink={"https://" + global.location.hostname}
           skipLink="#content"
           pageHeadingLink={"https://" + global.location.hostname}
           pageHeading = {store.getState().translate.GLOBAL_DEALER_LOCATOR}
         />
       </div>
     );
   }
}


export default HeaderComponent;