import React from 'react';
import { Dealer } from '../Shared/Dealer/';
import { func, array, shape } from 'prop-types';
import Loader from '../assets/images/gif/loaderOneGreen.gif';

class DealerDetailsList extends React.Component{
  constructor(props){
    super(props);
  }
  componentDidMount(){
    const {
      DealerDetailsAction = () => {},
    } = this.props;
    DealerDetailsAction();
  }
  
  render(){
    const {
      dealers = [],
      translate: {
        GDL_PRODUCTGROUP_ERROR= ''
      } = {},
      translate={},
      common={}
    } = this.props;
    let dealerLen = dealers.length;
    return(
      <div
        className={`dealer-list ${dealerLen > 2 ? 'add-overflow' : ''}`}
        id="dealer-list" data-test="dealer-list"
      >   
      
        { common.apiError ? <div className = "error-sms">Oops!Something went wrong please try again later</div>:
          common.goDisable == true && common.noDealer == false?
            <div className="loader-img">
              <img src={Loader} alt="loading..." /></div> : 
            dealerLen > 0 && common.noDealer == false ?           
              dealers.map((
                dealerProps
                , i) => { 
                return (
                  <Dealer
                    dealer={dealerProps }
                    translate={translate}
                    common={common}
                    key={`list-${i}`}
                    dealerNumber = {++i}
                  />
                );                
              })
              : <h5
                className={common.noDealer==true? "error-sms":"hidden"}
              >{GDL_PRODUCTGROUP_ERROR}
               
              </h5>
        }
      </div>
    );
  }
}
DealerDetailsList.propTypes = {
  DealerDetailsAction: func,
  dealers: array,
  translate: shape(),
  common: shape(),
};
export default DealerDetailsList;