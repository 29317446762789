import { combineReducers } from 'redux';
import translate from './localReducer';
import { CommonReducer } from './Shared/commonReducer';
import { PopUpData } from './Shared/PopUpData/';
import { DealerDetailsListReducers } from './DealerDetailsList/reducers';
import { FilterReducers } from './ViewResultFilters/reducers';
import {routerReducer } from 'react-router-redux'

const RootReducer = combineReducers({
  translate,
  dealerList: DealerDetailsListReducers,
  common: CommonReducer,
  Filterdata: FilterReducers,
  PopUpData,
  routing: routerReducer
});

export default RootReducer ;