import { any, bool, func, shape, string } from 'prop-types';
import React from 'react';
import DealerDetailsList from '../DealerDetailsList/';
import { goSearch, initAutoComplete } from '../Shared/';
import GoogleFilters from '../Shared/GoogleFilters/';
import { store } from '..';

class FindADealerList extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      gmap: false,
      modalFlag: false,
    };
  }

  componentWillMount(){
    const {
      GetGmapState
    } = this.props;
    store.dispatch({ type: 'SET_NO_DEALER', payload: false });
 
    GetGmapState(false);
  }

  componentDidMount(){
    const {
      dealers = [],
      common = {},
      translate = {},
      gmap,
      productGroupName,
      isProductGroupAvailable
    } = this.props;
    const {
      industriesObj: {
        name = '',
        id = ''
      } = {},
    } = common;
  
    if(this.props.common.selectedLocation != undefined && this.props.common.selectedLocation.length > 0){
      //console.log(this.props.common.selectedLocation)
      document.getElementById("google-page-level-search3").value = this.props.common.selectedLocation;
    }
   
  }


  handleChange = () => { 
    initAutoComplete();
  }

  handleGoSearch = () => {
    goSearch(this.props);    
  }

  handleListMapClick = () => {
    const {
      gmap,
      GetGmapState,
    } = this.props;
    GetGmapState(!gmap);
  }
  handleFindFilterClick = () => {
    const {
      setApplyFlag = () => { },
      isApplyFlag = false
    } = this.props;
    if (isApplyFlag === true) {
      setApplyFlag(false);
    } else {
      setApplyFlag(true);
    }
  }
  render() {
    const {
      translate: {
        GDL_FIND_A_DEALER = '',
        GDL_LIST = '',
        GDL_MAP = '',
        GDL_SHOWING_RESULT_FOR = '',
        GDL_CHANGE = '',
        GDL_PRODUCTGROUP_ERROR= '',
        GDL_CHOOSE_DEALER_CATEGORY= ''
      } = {},
      dealers = [],
      common = {},
      translate = {},
      gmap,
      productGroupName,
      isProductGroupAvailable
    } = this.props;
    const {
      currentlatlng: {
        lat = 0,
      } = {},
      industriesObj: {
        name = '',
        id = ''
      } = {},
      noDealer = false,
      isPlaceChanged
    } = common;

    return (
      <div className="list-outer-wrapper">
        <div className="fixed-panel">
          <div className="row">
            <div className="col-14">
              <h3 className="margin-header">{GDL_FIND_A_DEALER}</h3>
            </div>
          </div>
          <GoogleFilters {...this.props} displaydealers={true}  name="google-page-level-search3" />
          
          <div className={`row ${common.isMobile && lat ? 'is-mobile' : ''}`}>

            <div
              className={common.isMobile && lat ?
                'show' : 'hidden'
              }
            >
              <label> {GDL_LIST} &nbsp;  </label>
              <label className='switch' >
                <input
                  type="checkbox"
                  data-test="checkbox"
                  onClick={this.handleListMapClick}
                />
                <span className="slider round"></span>
              </label>
              <label>  {GDL_MAP}  </label>
            </div>

           
          </div>
          <div className="row spacebetween">
            <div className="show-result">
            <div>
              <h5 className={common.currentlatlng.lat != 0 && ((name != undefined && name.length > 0) || productGroupName) ? '' :'hidden'}>{GDL_SHOWING_RESULT_FOR}&nbsp;
                <strong>{ name != undefined && name.length > 0 ? name : productGroupName }</strong>&nbsp;
                <a 
                  href={"javascript:void(0)"}
                  onClick={this.handleFindFilterClick}>
                  {GDL_CHANGE}
                </a>
              </h5>
              <h5 className={!noDealer ? 'hidden':'show'}>
              <a 
                  href={"javascript:void(0)"}
                  onClick={this.handleFindFilterClick}>
                  {GDL_CHOOSE_DEALER_CATEGORY}
                </a>
              </h5>
              </div>
            </div>
          </div>
          <div id="row" className={lat ? 'hidden' : 'row'}>
            <div className="col-xl-24 donot-allow"></div>
          </div>
        </div>
        <div
          className={common.isMobile && gmap === true ||
            !lat ?
            'row dealers hidden' : 'row dealers  show'}
        >
          <DealerDetailsList
            dealers={dealers}
            translate={translate}
            common={common}
          />

        </div>

      </div>
    );
  }
}

FindADealerList.propTypes = {
  getLocalAction: func,
  setCurrentLatLng: func,
  setMeasumentUnit: func,
  DealerDetailsAction: func,
  getCountrycodeAction: func,
  setUsersLatLng: func,
  GetGmapState: func,
  handleFindFilterClick: func,
  enableGo: func,
  disableGo: func,
  searchParent: func,
  translate: shape({
    GDL_FIND_A_DEALER: string,
    GDL_LIST: string,
    MEASUREMENT_UNIT: string,
    GDL_PRODUCTGROUP_ERROR: string
  }),
  dealers: any,
  common: {
    shape : func,
    selectedLocation: string
  },
  gmap: bool,
  setApplyFlag: func,
  isApplyFlag: bool
};

export default FindADealerList;