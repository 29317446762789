import React from 'react';
import {
  Footer,
  FooterLink,
  List,
  ListItem
} from '@deere/ux.uxframe-react';


class FooterComponent extends React.Component{
 
  render(){
    return(
      <Footer>
        <List className="nav footer-list">
          <ListItem>
            <FooterLink
              href="https://www.deere.com/en/equipment/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Site Map
            </FooterLink>
          </ListItem>
          <ListItem>
            <FooterLink
              href="https://www.deere.com/en/privacy-and-data/"
              target="_blank" rel="noopener noreferrer"
            >
              Privacy and Data
            </FooterLink>
          </ListItem>
          <ListItem>
            <FooterLink
              href="https://www.deere.com/en/privacy-and-data/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Legal
            </FooterLink>
          </ListItem>
          <ListItem>
            <FooterLink
              href="https://www.deere.com/en/our-company/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
              
            </FooterLink>
          </ListItem>
          <ListItem>
            <FooterLink class="ot-sdk-show-settings" id="otFooter" style={{cursor:"pointer", color:"#666"}}>
            Cookies Preferences
            </FooterLink>
          </ListItem>
        </List>
        
      </Footer>
    );
  }
}

export default FooterComponent;