import axios from 'axios';
import { getEnv } from '../Environment';

// export const RequestHandler = ({
//   base = `${getEnv()}`,
//   dataUrl = '',
//   method = 'get',
//   resType = 'json',
//   requestData = {},
//   successCallBack = () => { },
//   errorCallBack = () => { }
// }) => {
//   axios({
//     method: method,
//     url: `${base}${dataUrl}`,
//     data: method === 'post' || method === 'put' ?
//       JSON.stringify(requestData) : null,
//     responseType: resType,
//     headers: method === 'post' || method === 'put' ?
//       { 'content-type': 'application/json' } : null,
//   })
//     .then((response) => {
//       successCallBack(response.data);
//     })
//     .catch((error) => {
//       errorCallBack(error);
//     });
// };



export const RequestHandler1 = ({
  base = `${getEnv()}`,
  dataUrl = '',
  method = 'get',
  resType = 'json',
  requestData = {}
}) => {
  return axios({
    method: method,
    url: `${base}${dataUrl}`,
    data: method === 'post' || method === 'put' ?
      JSON.stringify(requestData) : null,
    responseType: resType,
    headers: method === 'post' || method === 'put' ?
      { 'content-type': 'application/json' } : null,
  })
    .then((response) => {
      return response.data;
    });
  // .catch((error) => {
  //   return error;
  // });
};