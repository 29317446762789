import {
  SET_CURRENT_LOCAL,
  SET_DEFAULT_DISTANCE,
  SET_MEASUREMENT_UNIT
} from './Shared/Constants/ActionType';
var defaultLocal = {
  // language:"en",
  // finddealer:"Find The Dealer",
  // open:"Open",
  // viewours:"View Open hours",
  // direction:"direction",
  // close:"close"
};



const local = (state = defaultLocal, { type ='', payload ={} }) => {

  switch (type) {
  case SET_CURRENT_LOCAL:
    return payload;
  case SET_DEFAULT_DISTANCE:
    return { ...state, "SELECTED": payload };
  case SET_MEASUREMENT_UNIT:
    return { ...state, "MEASUREMENT_UNIT": payload };
  default:
    return state;
  }
};

export default local;