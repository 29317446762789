import { COUNTRIES, LOCALE_MAPPING } from '../Constants/AppConstants';

let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';

const initialState = {
  
  landingPageFlag:false,
  dealerCatPopupFlag: false,
  dealersListPanelFlag: false,
  locale:navigator.language && navigator.language.includes("-") ? navigator.language.replace("-", "_") :  localeFromMapping ,
};

export const PopUpData = (state = initialState,
  { type= '', payload = {} }) => {
    
  switch(type){
  case 'LANDING_PAGE_SUCCESS':
    return {
      landingPageFlag: payload,
      

    };
  case 'DEALER_CATEGORY_POPUP_SUCCESS':
    return {
      dealerCatPopupFlag: payload,
    };
  case 'DEALER_LIST_PANEL_DISPLAY_SUCCESS':
    return {
      dealersListPanelFlag: payload,
    };
  default: return state;
  }
};

