 
//const isMobile = window.screen.width <= 1024 ? true:false;
//let window;
import { COUNTRIES, LOCALE_MAPPING } from '../Shared/Constants/AppConstants';

let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';
const {
  window: {
    screen: {
      width = ''
    } = {}
  } = {},
}= global;
const initialState = {
  currentlatlng:{}, 
  goDisable:false,
  countryCode:'',
  isPlaceChanged:false,
  isInvalidAddress:false,
  noDealer:false,
  noIndustry:false,
  apiError: false,
  noRouteFound:false,
  googlemaploaded:false,
  dataloaded:false,
  BaiduLoaded: false,
  locationDetected:false,
  dealerFeedbackSubmitted: false,
  locale:navigator.language && navigator.language.includes("-") ? navigator.language.replace("-", "_") :  localeFromMapping,
  isMobile: global && global.window && width <= 1024 ? true:false,
  BaiduLocale: navigator.language && navigator.language.indexOf('zh') != -1 
};

export const CommonReducer = (state = initialState,
  { type= '', payload = {} }) => {
    
  switch(type){
  case 'SET_CURRENT_LATLNG':
    return {
      ...state,
      currentlatlng: payload
    };
  case 'SET_USERS_LATLNG':
    return {
      ...state,
      userslatlng: payload,
      locationDetected:true
    };
  case 'DISABLE_GO':
    return {
      ...state,
      goDisable:true
    };
  case 'ENABLE_GO':
    return {
      ...state,
      goDisable:false
    };
  case 'COUNTRY_CODE':
    return {
      ...state,
      countryCode: payload
    };
  case 'SET_LOCALE':
 
    return {
      ...state,
      locale: payload
    };
  case 'PLACE_CHANGE_TRUE':
    return {
      ...state,
      isPlaceChanged:true,
      selectedLocation:payload
    };
  case 'PLACE_CHANGE_FALSE':
    return {
      ...state,
      isPlaceChanged:false
    };
  case 'SET_INVALID_ADDR_ERROR_MSG':
    return {
      ...state,
      isInvalidAddress:payload
    };
  case 'SET_NO_DEALER':
    return{
      ...state,
      noDealer:payload
    };
  case 'TOGGLE_NO_ROUTE_FOUND':
    return{
      ...state,
      noRouteFound:!state.noRouteFound
    };
  case 'MAP_LOADED':
    return{
      ...state,
      googlemaploaded:payload
    };
  case 'DATA_LOADED':
    return{
      ...state,
      dataloaded:true
    };
  case 'BAIDU_LOADED':
    return{
      ...state,
      BaiduLoaded:true
    };
  // case 'SET_BAIDU_LOCALE':
  //   return{
  //     ...state,
  //     BaiduLocale: payload
  //   };
  case 'GET_GEOCODER_BAIDU_MAP':
    return{
      ...state,
      geocoderBaiDuMap: payload
    };
  case 'SET_BRAND':
    return{
      ...state,
      brand: payload
    };
  case 'SET_DEALER_CATEGORY':
    return{
      ...state,
      industriesObj: payload
    };
    case 'SET_NO_INDUSTRY':
      return{
          ...state,
          noIndustry : payload
      };
      case 'DEEPLINK_CHANGE':
    return{
      ...state,
      isDeeplinkChange: payload
    };
    case 'SET_API_ERROR_RECEIVED':
        return{
            ...state,
            apiError : payload
        };
    
    
    
    case 'DEALER_FEEDBACK_SUBMITTED':
            return {
                  ...state,
                  dealerFeedbackSubmitted: payload
                };
  default: return state;
  }
};

// SET_DEALER_CATEGORY

