import { array, bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { store } from '..';
import BaiduMap from '../BaiduMap/';
import MapWrapper from '../MapDisplay/MapWrapper';
import { COUNTRIES, LOCALE_MAPPING } from '../Shared/Constants/AppConstants';
import { LOCALES } from '../Shared/Constants/AppConstants';
import { MAX_MOBILE_WIDTH  } from '../Shared/Constants/AppConstants'
import {
  Asyncgdl, getCountry,
  getMeasumentUnit, onLandingPageAddStyle, setDirectionOnMap
} from '../Shared';
import DealerCategoryPopup from '../Shared/DealerCategoryPopup';
import FooterComponent from '../Shared/Footer/';
import HeaderComponent from '../Shared/Header/';
import LandingPageModal from '../Shared/LandingPageModal/';
import {
  DealerCategoryAction, setDeepLinkChange, DealersByIndustryAction, DealersByIndustryAction1, getProductGroupsAction, selectALLDataParam, selectEquipment, setApplyFlag, setFilterDataLength, setSelectedDataParam
} from '../ViewResultFilters/action';
import {
  DealerDetailsAction, dealersListPanelDispalyAction, dealer_category_popup_action, getCountrycodeAction, getGeocoderBaiduMap, getLocalAction, invalid_address_error_message_action,landing_page_action, setBrand, setCountryCode, setCurrentLatLng, setDealerCategoryIndustries, setLocale, setMeasumentUnit, setUsersLatLng, set_isPlaceChanged_false, set_isPlaceChanged_true, toggle_noroute_found
} from './action';
import DealerPage from '../DealerPage';
import { getAddressFromBaiduGeoCoder, getAddressFromGeoCoder } from './action';
const query = new URLSearchParams(window.location.search);
let deeplinkProductGroup = query.get("productGroup") ? query.get("productGroup") : undefined;
let deeplinkIndustry = query.get("industry") ? (query.get("industry") == 3 ? undefined : query.get("industry")) : undefined;

export class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      gmap: props.common.isMobile ? false : true,
      isFilterView: false,
      show: false,
      isLoaded: false
    };
      window.WrapperObj = this;
  }
 
  componentDidMount() {
    const {
      getLocalAction = () => { },
      setLocale = () => { },
      setBrand = ()=>{},
      GetGmapState =()=>{}
    } = this.props;
    let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale; 
    let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';
    let browserLocale =  navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping;
    const locale = deeplinkLocale != undefined ? deeplinkLocale : browserLocale;
    if(locale.includes('he')) {
      document.body.style.direction= "rtl";
      document.body.style.textAlign= "right";
      if(window.screen.width >= 1280) 
      {
      document.getElementsByClassName('uxf-footer-legal')[0].style.float = "right";
     document.getElementsByClassName('footer-list')[0].style.float = "left";
   }
   }
   else {
      document.body.style.direction= "ltr";
    }
    setLocale(locale);
    this.setState({isLoaded:true})
    
    const brand = 'johndeere';
    setBrand(brand);
    GetGmapState(true)
    
  getLocalAction(locale, brand).then((res) => {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.successCallback, this.errorCallback,
        );

      }
    });   

  }


  componentDidUpdate(prevProps) {
    const {
      common: {
        countryCode = '',
        currentLatLng = {}
      } = {},
      setCountryCode = () => { },
      DealerCategoryAction = () => { },
      setDeepLinkChange = () => { },
      dealer_category_popup_action = () => { }
    } = this.props;
    let brand="Johndeere";
    let country = this.props.match.params.country? this.props.match.params.country :""

    if (country.length != 0 && !COUNTRIES.includes(country)){
      this.props.history.replace({
        pathname: `/servlet`,
      
      })
    }
    if(this.state.isLoaded){
    document.getElementById('google-page-level-search2') ?this.props.selectedLocation:""
    this.setState({isLoaded : false})
    }
    
    let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale;
    deeplinkIndustry = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).industry == 3 ? undefined : window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).industry;
    //deeplinkIndustry = query.get("industry") ? (query.get("industry") == 3 ? undefined : query.get("industry")) : undefined;
    deeplinkProductGroup = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).productGroup;
    let language = navigator.language.split("-")
    
    
    let currentThis =  this;
    if(!deeplinkProductGroup && !deeplinkIndustry){
      window.onpopstate = ()=> {
        store.dispatch({ type: 'MAP_LOADED', payload:false });
        if(!store.getState().common.BaiduLocale)
          this.loadMapjs(language[0], language[1],currentThis.props.common.currentlatlng, true,true);
        else {
          this.BAIDU_LOADED(language[0], language[1],currentThis.props.common.currentlatlng, true,true);
        }
         dealer_category_popup_action(true)
      }
     
    }
    if (countryCode && countryCode !== prevProps.common.countryCode && deeplinkLocale == undefined && deeplinkProductGroup == undefined && deeplinkIndustry == undefined) {
      if(window.adobeDataLayer != undefined){
        window.adobeDataLayer.push({
          "event": "Page Loaded",
          "consent": window.OnetrustActiveGroups , 
          "page": {
           "breadCrumbs": [
           "servlet",
           'country='+countryCode
           ],
           "pageName": "gdl : "+ countryCode,
           "locale": deeplinkLocale && deeplinkLocale.length > 0 ? deeplinkLocale :language[0]+"_"+language[1],
           "siteCountry": countryCode,
           "siteLanguage": deeplinkLocale && deeplinkLocale.length > 0 ? deeplinkLocale :language[0]+"_"+language[1]
           },
           
          });
         }
      this.props.history.replace({
        pathname: `/servlet/country=${countryCode}`
      })
      
      
    }else if(countryCode && countryCode !== prevProps.common.countryCode && deeplinkLocale != undefined && deeplinkProductGroup == undefined && deeplinkIndustry == undefined){
      if(window.adobeDataLayer != undefined){
        window.adobeDataLayer.push({
          "event": "Country Deeplink Loaded",
          "consent": window.OnetrustActiveGroups , 
          "page": {
           "breadCrumbs": [
           "servlet",
           'country='+countryCode
           ],
           "locale": deeplinkLocale && deeplinkLocale.length > 0 ? deeplinkLocale :language[0]+"_"+language[1],
           "siteCountry": countryCode,
           "siteLanguage": deeplinkLocale && deeplinkLocale.length > 0 ? deeplinkLocale :language[0]+"_"+language[1]
           },
           
          });
         }
      this.props.history.replace({
        pathname: `/servlet/country=${countryCode}`,
        search: `?locale=${deeplinkLocale}`

      })
    }
    
     else if(deeplinkLocale != undefined && !LOCALES.includes(deeplinkLocale)){
      this.props.history.replace({
        pathname: `/servlet/country=${this.props.match.params.country? this.props.match.params.country :""}`      
      })

    } else if((countryCode && countryCode !== prevProps.common.countryCode && deeplinkProductGroup != undefined) || (deeplinkProductGroup != undefined && this.props.common.isDeeplinkChange) ){
     let industryId = this.props.common.industriesObj ? this.props.common.industriesObj.id : deeplinkProductGroup
     setDeepLinkChange(false)
      if(deeplinkLocale != undefined && LOCALES.includes(deeplinkLocale)) {
        this.props.history.replace({
          pathname: `/servlet/country=${countryCode}`,
          search: `?locale=${deeplinkLocale}&productGroup=${industryId}`
        })
      }else {
      this.props.history.replace({
        pathname: `/servlet/country=${countryCode}`,
        search: `?productGroup=${industryId}`

      })
    }
    } else if((countryCode && countryCode !== prevProps.common.countryCode && deeplinkIndustry != undefined) || (deeplinkIndustry != undefined && this.props.common.isDeeplinkChange) ){
      let industryId = this.props.common.industriesObj ? this.props.common.industriesObj.id : deeplinkIndustry
      setDeepLinkChange(false)
      if(deeplinkLocale != undefined && LOCALES.includes(deeplinkLocale)) {
        this.props.history.replace({
          pathname: `/servlet/country=${countryCode}`,
          search: `?locale=${deeplinkLocale}&industry=${industryId}`
        })
      }else {
      this.props.history.replace({
        pathname: `/servlet/country=${countryCode}`,
        search: `?industry=${industryId}`

      })
    }
     }
    else if((deeplinkProductGroup != undefined && deeplinkProductGroup == 0) ||
    (deeplinkIndustry != undefined && deeplinkIndustry == 0)){
      deeplinkProductGroup = undefined;
      deeplinkIndustry = undefined;
      this.props.history.replace({
        pathname: `/servlet/country=${this.props.match.params.country? this.props.match.params.country :""}`      
      })
    }
    
    onLandingPageAddStyle();
  }
 
  handleClose = () => {
    this.setState({ show: false });
  }

  GetGmapState = (props) => {
    this.setState({ gmap: props });
    setTimeout(() => window.onResize(), 2000);
  }

 

  fetchNearestDealers = (currentLatLng,displaydealers,isCountryDeepLink) => {
    let language = navigator.language.split("-");
    const {
      DealersByIndustryAction= () => { },
      DealerCategoryAction = () => { },
      getProductGroupsAction = () => { },
      getCountrycodeAction = () => { },
      setMeasumentUnit = () => { },
      setCountryCode = () => { }
    } = this.props;
    return Asyncgdl((resolve, reject) => {
     getAddressFromGeoCoder(currentLatLng.lat, currentLatLng.long, store.getState().common.BaiduLocale).then( response => {

     const address = response;
     
       if(document.getElementById("google-page-level-search2") != null){
        store.dispatch({ type: 'PLACE_CHANGE_TRUE', payload: document.getElementById("google-page-level-search2").value });
       }
       else if(document.getElementById("google-page-level-search3")){
        store.dispatch({ type: 'PLACE_CHANGE_TRUE', payload: document.getElementById("google-page-level-search3").value });
       }
       else if(!this.props.common.selectedLocation){
        store.dispatch({ type: 'PLACE_CHANGE_TRUE', payload: address });
       }
       let cc = '';
        getCountrycodeAction(currentLatLng.lat, currentLatLng.long).then((responseData) => {
          cc = responseData;
    
      let countryCode = ""
       if(this.props.match.params.country && isCountryDeepLink){
        countryCode = this.props.match.params.country
      } else {
        countryCode = cc;
       
        }
        let industryIdfromDeepLink = deeplinkProductGroup ? deeplinkProductGroup : deeplinkIndustry;
        let IndustryId = store.getState().common.industriesObj == undefined ? industryIdfromDeepLink : store.getState().common.industriesObj.id;
        if (countryCode) {
          let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale;
          let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';
          let browserLocale =  navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping;
          let unit = getMeasumentUnit(countryCode);
          let brand="Johndeere";
          setCountryCode(countryCode);
          setMeasumentUnit(unit);
          const dataParam = {
          "industryId":  IndustryId,
          "countryCode": countryCode,
          "brand": brand,
          "equipmentName": "",
          "equipmentId": 0,
          "locale":deeplinkLocale != undefined ? deeplinkLocale : browserLocale,

          "allIndustriesSelected": false,
          "latitude": currentLatLng.lat,
          "longitude": currentLatLng.long,
          "radius": "0 " + unit
          }
          console.log("dataparam = "+ dataParam.locale);
          const dataParam1 = {
            countryCode,
            locale:deeplinkLocale != undefined ? deeplinkLocale : browserLocale,

            brand
          };

          //getProductGroupsAction(dataParam1);
          DealerCategoryAction(dataParam1);
          DealersByIndustryAction(dataParam,false,displaydealers).then((res) => {
            resolve(language[0], language[1]);
          });
    
        } else {
          alert("Country not detected");
        }
      });
      });
      });
  }

  successCallback = (position) => {
    var currentLatLng =
      { lat: position.coords.latitude, long: position.coords.longitude };
      if(!(this.props.common.currentlatlng.lat && this.props.common.currentlatlng.long) ){
    this.props.setCurrentLatLng(currentLatLng);
      }
    this.props.setUsersLatLng(currentLatLng);
  if((!deeplinkProductGroup || deeplinkProductGroup == 0) && (!deeplinkIndustry || deeplinkIndustry == 0)){
    this.fetchNearestDealers(currentLatLng,false,true).then((lang1, lang2) => {
      const {
        common = {},
        dealer_category_popup_action = () => { }, 
      } = this.props;
      if (common.locale &&  !navigator.language.startsWith('zh')){        
        this.loadMapjs(lang1, lang2, currentLatLng, true,true);
        dealer_category_popup_action(true); //currently equipment search is hidden so dealer category is called else landing page should call
       
      } else { 
        this.BAIDU_LOADED(lang1, lang2, currentLatLng, true,true);
        dealer_category_popup_action(true);//currently equipment search is hidden so dealer category is called else landing page should call
      }
      let dealerlist = document.querySelectorAll(".dealer-list>.list-wrap");
      let selecteddealerlist = dealerlist[0];
      let wrapper = null;
      wrapper = document.querySelector("#list-wrap-wrapper");
      if(selecteddealerlist != undefined) {
      if (wrapper)
        wrapper.innerHTML = selecteddealerlist && selecteddealerlist.outerHTML;
      }
      let directionclick =
        document.querySelector("#list-wrap-wrapper .direction");
      if (directionclick) {
        directionclick.addEventListener(
          "click", () => {
            setDirectionOnMap({
              slat: 0, slng: 0, lat: window.markers[0].position.lat(),
              lng: window.markers[0].position.lng()
            });
          }, false);
      }
    });
  }
  }

 
  searchParent = (displaydealers) => {
    this.props.set_isPlaceChanged_false();
    this.searchcallback(displaydealers);
  }

  updateDealer() {
    const dataParam1 = {
      "latitude": 51.507351,
      "longitude": -0.127758,
      "countrycode": "GB",
      "brand": "Johndeere",
      "radius": "0 MI",
      "locale": "en_US"
    };
    this.props.DealerDetailsAction(dataParam1).then(() => {
    });
  }
  errorCallback = () => {
    const {
      common = {},
      setCurrentLatLng = () => { },
      dealer_category_popup_action = () => { },
      dealersListPanelDispalyAction =() => { } ,
      DealerCategoryAction = () => { }

    } = this.props;
    let language = navigator.language.split("-");
    var currentLatLng = {lat:0,long:0}
    this.props.setUsersLatLng(currentLatLng);
    this.props.setCurrentLatLng(currentLatLng);
    dealersListPanelDispalyAction(true)
    
    if(deeplinkProductGroup || deeplinkIndustry){
    store.dispatch({ type: 'MAP_LOADED', payload:false });
    let deeplinkLocale = query.get("locale");
    let countryCode = this.props.match.params.country? this.props.match.params.country :""
    let localeFromMapping = LOCALE_MAPPING[navigator.language] ? LOCALE_MAPPING[navigator.language] : 'en_US';
    let browserLocale =  navigator.language.includes("-") ? navigator.language.replace("-", "_") : localeFromMapping;
    const brand = 'johndeere';
      const dataParam = {
        countryCode,
        locale:deeplinkLocale != undefined ? deeplinkLocale : browserLocale,

        brand
      };
      DealerCategoryAction(dataParam);
    }
    if (!store.getState().common.BaiduLocale ) {
     this.loadMapjs(language[0], language[1], currentLatLng, true,true);
      dealer_category_popup_action(true); //currently equipment search is hidden so dealer category is called else landing page should call

    } else {
      this.BAIDU_LOADED(language[0], language[1], currentLatLng, true,true);
      dealer_category_popup_action(true);//currently equipment search is hidden so dealer category is called else landing page should call
    }
  }

  render() {
    const {
      dealers = [],
      common = {},
      setCurrentLatLng = () => { },
      setUsersLatLng = () => { },
      translate = {},
      set_isPlaceChanged_true = () => { },
      set_isPlaceChanged_false = () => { },
      setFilterLength = 0,
      invalid_address_error_message_action = () => { },
      toggle_noroute_found = () => { },
      industries = [],
      productgroups = [],
      isApplyFlag = false,
      SelectedEquipment = {},
      DealerCategoryAction = () => { },
      getProductGroupsAction = () => { },
      DealersByIndustryAction = () => { },
      setFilterDataLength = () => { },
      setApplyFlag = () => { },
      selectEquipment = () => { },
      setSelectedDataParam = () => { },
      selectALLDataParam = () => { },
      getGeocoderBaiduMap = () => { },
      landing_page_action = () => { },
      dealer_category_popup_action = () => { },
      dealersListPanelDispalyAction = () => {},
      PopUpData = {},
      setDealerCategoryIndustries = () => {}
    } = this.props;
    const {
      gmap,
      isFilterView,
    } = this.state;
    let ListParam = {
      searchParent: this.searchParent,
      GetGmapState: this.GetGmapState,
      fetchNearestDealers: this.fetchNearestDealers,
      deeplinkProductGroup: deeplinkProductGroup ? deeplinkProductGroup : (this.props.location.state ? this.props.location.state.deepLinkProductGroup : undefined),
      deeplinkIndustry: deeplinkIndustry ? deeplinkIndustry : (this.props.location.state ? this.props.location.state.deeplinkIndustry : undefined),
      translate,
      dealers,
      common,
      PopUpData,
      setCurrentLatLng,
      setUsersLatLng,
      gmap,
      history:this.props.history,
      set_isPlaceChanged_false,
      setFilterLength,
      invalid_address_error_message_action,
      isFilterView,
      setApplyFlag,
      isApplyFlag,
      getGeocoderBaiduMap,
      state: this.state,
      handleClose: this.handleClose,
      landing_page_action,
      dealer_category_popup_action,
      industries,      
      dealersListPanelDispalyAction,
      DealersByIndustryAction,
      setDealerCategoryIndustries
    };

    let ModalPram = {
      state: this.state,
      handleClose: this.handleClose,
      translate,
      common,
      landing_page_action,
      dealer_category_popup_action,
      industries,
      PopUpData,
      productgroups,
      selectEquipment,

      DealerCategoryAction,
      getProductGroupsAction,
      DealersByIndustryAction,
      setFilterDataLength,
      setApplyFlag,
      setSelectedDataParam,
      selectALLDataParam,
      isFilterViewFlag: isFilterView,
      setDealerCategoryIndustries,

      getGeocoderBaiduMap,
      searchParent: this.searchParent,
      fetchNearestDealers: this.fetchNearestDealers,
      setCurrentLatLng,
      invalid_address_error_message_action,
      dealersListPanelDispalyAction,
      SelectedEquipment
    };

    return (
    deeplinkProductGroup || deeplinkIndustry ||
    (this.props.location.state ? this.props.location.state.deepLinkProductGroup : undefined) ||
    (this.props.location.state ? this.props.location.state.deeplinkIndustry : undefined)
    ? <DealerPage {...ListParam}  dealersListPanelFlag={true}/>:
      <div>
        
        <HeaderComponent />
        
        <div className="container-fluid main-container">
          <div className="row">
            
            <div
              className={common.isMobile && gmap === false ?
                'col-xl-17 resetmarginpadding hidden' :
                'col-xl-17 resetmarginpadding show'}
            >

              <div
                className={
                  `alert alert-danger ${common.noRouteFound ? "" : "hidden"}`
                }
                role="alert"
              >
                Sorry, we could not calculate directions
                from your location to selected dealer.
                <button
                  type="button" className="close"
                  data-test="close"
                  data-dismiss="alert" aria-label="Close"
                  onClick={toggle_noroute_found}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {
                common.BaiduLocale == true ?
                  <BaiduMap
                    setBaiduMap= {obj => this.BAIDU_LOADED = obj}
                    dealers={dealers}
                    common={common}
                    searchP={obj => this.searchcallback = obj}
                    fetchNearestDealers={this.fetchNearestDealers}
                  />
                  :
                  <MapWrapper
                    setMap={obj => this.loadMapjs = obj}
                    searchP={obj => this.searchcallback = obj}
                    dealers={dealers}
                    fetchNearestDealers={this.fetchNearestDealers}
                    common={common}
                    setCurrentLatLng={setCurrentLatLng}
                    set_isPlaceChanged_true={set_isPlaceChanged_true}
                    set_isPlaceChanged_false={set_isPlaceChanged_false}
                    invalid_address_error_message_action={
                      this.props.invalid_address_error_message_action
                    }
                    AllProps={this.props}
                  />

              }

            </div>
          </div>
          <div
            className={`mobile ${common.isMobile && gmap === true ?
              'show row' : 'hidden'}`}
            data-test="mobile"
          >
            <div className="dealer-list">
              <div id="list-wrap-wrapper">
                <div></div>
              </div>
            </div>
            
          </div>
          <LandingPageModal {...ModalPram} />
          <DealerCategoryPopup {...ListParam} />
        </div>       
        <FooterComponent /> 
      </div>
            

    );
  }
}

Wrapper.propTypes = {
  getLocalAction: func,
  setCurrentLatLng: func,
  setMeasumentUnit: func,
  DealerDetailsAction: func,
  getCountrycodeAction: func,
  setUsersLatLng: func,
  enableGo: func,
  disableGo: func,
  setLocale: func,
  setFilterLength: number,
  translate: shape({
    GDL_FIND_A_DEALER: string,
    GDL_LIST: string,
    MEASUREMENT_UNIT: string,
    GDL_DIRECTIONS: string
  }),
  dealers: array,
  setCountryCode: func,
  common: shape(),
  set_isPlaceChanged_true: func,
  set_isPlaceChanged_false: func,
  invalid_address_error_message_action: func,
  toggle_noroute_found: func,
  industries: array,
  productgroups: array,
  isApplyFlag: bool,
  SelectedEquipment: shape(),
  selectedDataPram: shape({}),
  DealerCategoryAction: func,
  getProductGroupsAction: func,
  DealersByIndustryAction: func,
  setFilterDataLength: func,
  setDeepLinkChange: func,
  setApplyFlag: func,
  selectEquipment: func,
  setSelectedDataParam: func,
  selectALLDataParam: func,
  getGeocoderBaiduMap: func,
  landing_page_action: func,
  dealer_category_popup_action: func,
  DealersByIndustryAction1: func,
  DealerByIndustriesData: shape({}),
  dealersListPanelDispalyAction: func,
  PopUpData: shape(),
  setBrand: func,
  setDealerCategoryIndustries: func,
};



const mapStateToProps = state => ({
  dealers: state.dealerList.DealerList,
  translate: state.translate,
  common: state.common,
  PopUpData: state.PopUpData,
  setFilterLength: state.Filterdata.setFilterLength,
  DealerByIndustriesData: state.Filterdata.DealerByIndustriesData,
  industries: state.Filterdata.DealerIndustries,
  productgroups: state.Filterdata.ProductGroups,
  isApplyFlag: state.Filterdata.isApply,
  SelectedEquipment: state.Filterdata.SelectedEquipment,
  selectedDataPram: state.Filterdata.selectedDataPram,
});


const mapDispatchToProps = dispatch => bindActionCreators({
  DealerDetailsAction,
  getLocalAction,
  setMeasumentUnit,
  setCurrentLatLng,
  setUsersLatLng,
  getCountrycodeAction,
  setCountryCode,
  setLocale,
  set_isPlaceChanged_true,
  set_isPlaceChanged_false,
  invalid_address_error_message_action,
  toggle_noroute_found,
  DealerCategoryAction,
  getProductGroupsAction,
  DealersByIndustryAction,
  setFilterDataLength,
  setApplyFlag,
  selectEquipment,
  setSelectedDataParam,
  selectALLDataParam,
  getGeocoderBaiduMap,
  landing_page_action,
  dealer_category_popup_action,
  setDeepLinkChange,
  DealersByIndustryAction1,
  dealersListPanelDispalyAction,
  setBrand,
  setDealerCategoryIndustries
},
dispatch
);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper));


