import React from 'react';
import { func, shape, bool, string, array } from 'prop-types';
import { CloseIcon } from '../icons/closeIcon';
import { SearchIcon } from '../icons/SearchIcon';
import { goSearch, initAutoComplete, reset, getCountry } from '../../Shared/';
import { getCountrycodeAction, setCountryCode } from '../../Wrapper/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
class GoogleFilters extends React.Component {
  constructor(props) {
    super(props);
    this.inputref = React.createRef();
    this.state = {
      industryIds: '',
      dealers: [],
      allIndustriesSelected: false,
      handleChangeLocation: false,
      industryName: '',
      handleChangestatus: true
    };
    window.GoogleFiltersObj = this;
    
  }

  componentDidMount(){
    const {

      translate: {
        GDL_YOUR_LOCATION = ''
      } = {}
    } = this.props;
    if(document.getElementById('google-page-level-search2')) {
      if (this.props.common.selectedLocation){
        document.getElementById('google-page-level-search2').value = this.props.common.selectedLocation;
      }
      else{
        document.getElementById('google-page-level-search2').placeholder = GDL_YOUR_LOCATION;
      }

      
    }
  }
  handleChange = (event, dealersListPanelFlag) => {
    const {
      currentTarget = {},
    } = event;
    var input = currentTarget;
    if (this.state.handleChangestatus) {
      initAutoComplete(input, dealersListPanelFlag);
      this.setState({ handleChangestatus: false })
    }
    $(".pac-container").css("visibility", "visible");
  }

  handleEnterPress(event, dealersListPanelFlag, displaydealers){
  
    if(event.key == "Enter"){
    
      $(".pac-container").css("visibility", "hidden");
  
     goSearch(this.props, this.inputref.current, displaydealers);   
    }

}


  handleSelectedLocation = (industryId, industryName, lat, long, locale) => {
    const {
      DealersByIndustryAction = () => { },
      getCountrycodeAction = () => { },
      setCountryCode = () => { },
      setSelectedDataParam = () => { },
      dealersListPanelDispalyAction = () => { },
      setDealerCategoryIndustries = () => { },
      setCurrentLatLng = () => { }

    } = this.props;


    getCountrycodeAction(lat, long).then((responseData) => {
      let countryCode = responseData;

      if (countryCode) {
        setCountryCode(countryCode);
        const brand = 'johndeere';
        let SelectedEquipmentID = 0;
        let SelectedEquipmentName = '';

        let AllIndtriesElement = false;

        this.setState({
          handleChangeLocation: true
        });

        const dataParam = {
          "industryId": industryId,
          "countryCode": countryCode,
          "brand": brand,
          "equipmentName": SelectedEquipmentName,
          "equipmentId": SelectedEquipmentID,
          "locale": locale,
          "allIndustriesSelected": AllIndtriesElement,
          "latitude": lat,
          "longitude": long,
          "radius": "0 MI"
        };
        const selectedParam = {
          "industryIds": industryId,
          "allIndustriesSelected": AllIndtriesElement,
        };
        var currentLatLng =
          { lat: lat, long: long };
        setCurrentLatLng(currentLatLng);
        setSelectedDataParam(selectedParam);
        dealersListPanelDispalyAction(true);
        DealersByIndustryAction(dataParam,false);
        let industriesObj = {
          name : industryName,
          id: industryId
        };
        setDealerCategoryIndustries(industriesObj);
      } else {
        alert("Country not detected");
      }
    });


  };

  handleChangeLocation = (industryId) => {
    const {
      common: {
        currentlatlng: {
          lat = 0,
          long = 0,
        } = {},
        locale = ''
      } = {},

      DealersByIndustryAction = () => { },
      getCountrycodeAction = () => { },
      setCountryCode = () => { },
      setSelectedDataParam = () => { },
      dealersListPanelDispalyAction = () => { },
      setDealerCategoryIndustries = () => { },
      setCurrentLatLng = () => { }

    } = this.props;


    getCountrycodeAction(lat, long).then((responseData) => {
      let countryCode = responseData;

      if (countryCode) {
        setCountryCode(countryCode);
        const brand = 'johndeere';
        let SelectedEquipmentID = 0;
        let SelectedEquipmentName = '';

        let AllIndtriesElement = false;

        this.setState({
          handleChangeLocation: true
        });

        const dataParam = {
          "industryId": industryId,
          "countryCode": countryCode,
          "brand": brand,
          "equipmentName": SelectedEquipmentName,
          "equipmentId": SelectedEquipmentID,
          "locale": locale,
          "allIndustriesSelected": AllIndtriesElement,
          "latitude": lat,
          "longitude": long,
          "radius": "0 MI"
        };
        const selectedParam = {
          "industryIds": industryId,
          "allIndustriesSelected": AllIndtriesElement,
        };
        var currentLatLng =
          { lat: lat, long: long };
        setCurrentLatLng(currentLatLng);
        setSelectedDataParam(selectedParam);
        dealersListPanelDispalyAction(true);
        DealersByIndustryAction(dataParam,false);
        let industriesObj = {
          id: industryId
        };
        setDealerCategoryIndustries(industriesObj);
      } else {
        alert("Country not detected");
      }
    });


  };


  render() {
    const {

      translate: {
        GDL_YOUR_LOCATION = '',
        GDL_ERROR_MESSAGE = '',
        GDL_ENTER_ADDRESS = '',
        GDL_GO = ''
      } = {},
      common: {
        goDisable = false,
        isInvalidAddress = false,
      } = {},
      PopUpData: {
        dealersListPanelFlag = false,
      } = {},
      set_isPlaceChanged_false = () => { },
      displaydealers = false,
      name
    } = this.props;

    return (
      <div
        data-test="filtersdata"
        className={`input-group filtersdata uxf-search
          ${isInvalidAddress == true ? "has-error" : ""}`}
      >
        <input
          ref={this.inputref}
          id={name}
          aria-label="Search"
          className="uxf-search-bar form-control"
          type="search"
          data-test="form-control"
          placeholder={GDL_ENTER_ADDRESS}
          name="s"
          onChange={(event) => this.handleChange(event, dealersListPanelFlag)}
          onKeyDown={(e) => set_isPlaceChanged_false(e)}
          onKeyUp={(e) => this.handleEnterPress(e, dealersListPanelFlag, displaydealers)}

        />



        <div id="searchResultPanel"></div>
        <div className="input-group-append">
          <button
            className="gdl-close-btn"
            data-test="gdl-close-btn"
            onClick={() => reset(this.inputref)}
          >
            <CloseIcon />
          </button>
          <button
            disabled={goDisable}
            type="button"
            aria-label="Submit search"
            id="submit-form11" onClick={
              () => goSearch(this.props, this.inputref.current, displaydealers)
            }
            data-test="go-icon"
            className={`go-icon uxf-search-bar-submit-icon 
              btn btn-primary  ${dealersListPanelFlag ? 'btn-go' : ''}
              ${goDisable ? 'enable' : ''}`}
          >
            {
              dealersListPanelFlag ? GDL_GO : <SearchIcon
                color={'uxf-icon-search'}
              />
            }
          </button>
        </div>
        <div
          className=
          {isInvalidAddress == true ? "gdl-has-error" : "hidden"}
        >{GDL_ERROR_MESSAGE}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  getCountrycodeAction,
  setCountryCode
},
  dispatch
);


GoogleFilters.propTypes = {
  getCountrycodeAction: func,
  setCountryCode: func,
  common: shape({
    goDisable: bool,
    isInvalidAddress: bool
  }),
  locale: string,
  countryCode: string,
  PopUpData: shape({
    dealersListPanelFlag: bool,
  }),
  translate: shape({
    GDL_FIND_A_DEALER_NEAR_YOU: string,
    GDL_FIND_DEALERS: string,
    GDL_YOUR_LOCATION: string,
  }),
  Filterdata: shape({
    DealerIndustries: array
  }),
  set_isPlaceChanged_false: func,


};
export default connect(
  null,
  mapDispatchToProps
)(GoogleFilters);
