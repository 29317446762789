const initialState = {};

export const DealerDetailsListReducers = (state = initialState,
  { type= '', payload = {} }) => {    
  switch(type){
  case 'DEALER_DETAILS_SUCCESS':
    return {
      ...state,
      DealerList: payload
    };
  default: return state;

  }
};