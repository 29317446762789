import { RequestHandler1 } from '../Shared/RequestHandler';
import {
  SET_CURRENT_LOCAL,
  SET_MEASUREMENT_UNIT
} from '../Shared/Constants/ActionType';
import { getEnv } from "../Shared/Environment";
import axios from 'axios';
import { getCountry } from '../Shared';
import { store } from '..';

export const Dispatcher = (DataType, dispatch, responseData) => {
  dispatch({
    type: DataType,
    payload: responseData
  });
};

export const DealerDetailsAction = (dataParam,
  displaydealers=true) => (dispatch) => {
  let inputParam = {
    dataUrl: `/dealers`,
    method: 'post',
    requestData: dataParam,

  };
  if(displaydealers===false){
    Dispatcher('DATA_LOADED', dispatch);
    Dispatcher('DEALER_DETAILS_SUCCESS', dispatch, []);
    Dispatcher('Filter_DATA_LENGTH', dispatch, 0);
    Dispatcher('ENABLE_GO', dispatch);
    return Promise.resolve([]);
  }else{
    return RequestHandler1(inputParam).then((responseData) => {
      Dispatcher('DATA_LOADED', dispatch);
      Dispatcher('DEALER_DETAILS_SUCCESS', dispatch, responseData);
      Dispatcher('Filter_DATA_LENGTH', dispatch, 0);
      Dispatcher('ENABLE_GO', dispatch);

      if (responseData.length > 0) {
        Dispatcher('SET_NO_DEALER', dispatch, false);
      } else {
        Dispatcher('SET_NO_DEALER', dispatch, true);
      }
      return responseData;
    });
  }
};

export const getCountrycodeAction = (lat, long) => (dispatch) => {
  Dispatcher('DISABLE_GO', dispatch);
  var baiduLocale = store.getState().common.BaiduLocale;
  if(!baiduLocale){
    return axios({
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyClSN8cZpnl2922PZReSf6Igc-7ucJEDf0`,
      responseType: 'json',
    }).then((response) => {
      return getCountry(response.data.results, response.data.plus_code);
    }).catch(() => {
      return Promise.resolve('CN');
    });
  }else{
    return Promise.resolve('CN');
  }  
};

export function getAddressFromGeoCoder(lat, long, BaiduLocale) {

  return new Promise((resolve) => {
    if(!BaiduLocale) {
 
      return axios({
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyClSN8cZpnl2922PZReSf6Igc-7ucJEDf0`,
        responseType: 'json',
      })
        .then((response) => {
          resolve(response.data.results[0].formatted_address);
        }).catch(() => {
          let point = new BMap.Point(long, lat);
          var gc = new BMap.Geocoder();
          gc.getLocation(point, function(rs){
            if (rs && rs.address) {
              resolve(rs.address);
            }
          });
        });
    } else {
      let point = new BMap.Point(long, lat);
      var gc = new BMap.Geocoder();
      gc.getLocation(point, function(rs){
        if (rs && rs.address) {
          resolve(rs.address);
        }
      });}
  });
}

export const getLocalAction = (
  locale = '', brand = ''
) => Dispatch => {
  const url = `/translationTerms?locale=${locale}&brand=${brand}`;
  let inputParam = {
    base: `${getEnv()}`,
    dataUrl: url,
    method: 'get',
    resType: 'json'
  };

  return RequestHandler1(inputParam)
    .then((responseData) => {
      const resData = { "SELECTED": "50", ...responseData };
      Dispatcher(SET_CURRENT_LOCAL, Dispatch,
        resData
      );
      return responseData;
    });
};
export const setMeasumentUnit = (unit) => Dispatch => {
  Dispatcher(SET_MEASUREMENT_UNIT, Dispatch, unit);
};

export const setUsersLatLng = (latlng) => Dispatch => {
  Dispatcher('SET_USERS_LATLNG', Dispatch, latlng);
};
export const setCurrentLatLng = (latlng) => Dispatch => {
  Dispatcher('SET_CURRENT_LATLNG', Dispatch, latlng);
};

export const setCountryCode = (countryCode) => Dispatch => {
  Dispatcher('COUNTRY_CODE', Dispatch, countryCode);
};

export const setLocale = (locale) => Dispatch => {
  Dispatcher('SET_LOCALE', Dispatch, locale);
};

export const set_isPlaceChanged_true = () => Dispatch => {
  Dispatcher('PLACE_CHANGE_TRUE', Dispatch);
};

export const set_isPlaceChanged_false = () => Dispatch => {
  Dispatcher('PLACE_CHANGE_FALSE', Dispatch);
};

export const toggle_noroute_found = () => Dispatch => {
  Dispatcher('TOGGLE_NO_ROUTE_FOUND', Dispatch);
};

export const invalid_address_error_message_action = (flag) => Dispatch => {
  Dispatcher('SET_INVALID_ADDR_ERROR_MSG', Dispatch, flag);
  Dispatcher('DEALER_DETAILS_SUCCESS', Dispatch, []);
};

export const landing_page_action = (landingPageFlag) => Dispatch => {
  Dispatcher('LANDING_PAGE_SUCCESS', Dispatch, landingPageFlag);
};

export const dealer_category_popup_action =
  (dealerCatPopupFlag) => Dispatch => {
    Dispatcher('DEALER_CATEGORY_POPUP_SUCCESS', Dispatch, dealerCatPopupFlag);
  };

export const dealersListPanelDispalyAction =
  (dealersListPanelFlag) => Dispatch => {
    Dispatcher(
      'DEALER_LIST_PANEL_DISPLAY_SUCCESS',
      Dispatch, dealersListPanelFlag
    );
  };
export const setBrand =
  (brand) => Dispatch => {
    Dispatcher(
      'SET_BRAND',
      Dispatch, brand
    );
  };
export const setDealerCategoryIndustries =
  (industriesObj) => Dispatch => {
    Dispatcher(
      'SET_DEALER_CATEGORY',
      Dispatch, industriesObj
    );
  };
// export const setBaiduLocaleFlag = () => Dispatch => {
//   let currentLocale = navigator.language;
//   let BLocale = currentLocale && currentLocale.indexOf('zh') != -1;
//   Dispatcher('SET_BAIDU_LOCALE', Dispatch, BLocale);
// };


///This code is blocking in JD network

export const getGeocoderBaiduMap = (
  txt = ''
) => Dispatch => {
  // const url = `/translationTerms?locale=${locale}&brand=${brand}`;
  const url = `/geocoder?address=${txt}&output=json&key=37492c0ee6f924cb5e934fa08c6b1676`;
  let inputParam = {
    base: 'http://api.map.baidu.com',
    dataUrl: url,
    method: 'get',
    resType: 'json'
  };

  return RequestHandler1(inputParam)
    .then((responseData) => {
      Dispatcher('GET_GEOCODER_BAIDU_MAP', Dispatch, responseData);
      return responseData;
    });
};

