import React from 'react';

export const LocationIcon = () =>{
  return (
    <svg focusable="false"
      aria-hidden="true"
      className="locationIconMap"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path 
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z">
      </path><path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  );
};

export const DealerListSvg = (dealerNumber) =>{
  return (
    <svg focusable='false' 
    aria-hidden='true'
    fill='#367C2B' 
    xmlns='http://www.w3.org/2000/svg' 
    width='31'
    height='31' 
    viewBox='3 2 21 20'>
    <path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5'/>
    <text x='12' y='11' fill='white' text-anchor='middle' font-size='8px' font= '8px Verdana, Helvetica, Arial, sans-serif' >
    {dealerNumber}</text>
    </svg>
  );
};

export const MarkerNumber = (dealerNumber) =>{
  let greenIconSvg =`<svg focusable='false' aria-hidden='true' fill='#367C2B' xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='3 2 21 20'><path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5'/><text x='12' y='12' fill='white' text-anchor='middle' font-size='8px'>${dealerNumber}</text><style><![CDATA[text { font: 8px Verdana, Helvetica, Arial, sans-serif; }]]></style></svg>`;
  var encodedGreenIconSvg =  encodeURIComponent(greenIconSvg);
  return encodedGreenIconSvg;
};

export const MarkerNumberhover = (dealerNumber) =>{
  let greenIconSvg =`<svg focusable='false' aria-hidden='true' fill='#ffde00' xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='3 2 21 20'><path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5'/><text x='12' y='12' fill='black' text-anchor='middle' font-size='8px'>${dealerNumber}</text><style><![CDATA[text { font: 8px Verdana, Helvetica, Arial, sans-serif; }]]></style></svg>`;
  var encodedGreenIconSvg =  encodeURIComponent(greenIconSvg);
  return encodedGreenIconSvg;
};

