import { RequestHandler, RequestHandler1 } from '../Shared/RequestHandler';
import { store } from '..';


export const Dispatcher = (DataType, dispatch, responseData) =>
  dispatch({
    type: DataType,
    payload: responseData,
  });


export const DealerCategoryAction = ({
  countryCode, locale, brand
}) => (dispatch) => {
  Dispatcher('SET_NO_INDUSTRY', dispatch , true);
  Dispatcher('SET_API_ERROR_RECEIVED',dispatch, false);
  let inputParam = {
    // base:'https://servicesext.tal.deere.com',
    dataUrl:
      `/industries?locale=${locale}&countryCode=${countryCode}&brand=${brand}`,
    method: 'get',
  };
  return RequestHandler1(inputParam)
    .then((responseData) => {
      if(responseData.length > 0){
        Dispatcher('SET_NO_INDUSTRY', dispatch , false);
      }
      Dispatcher('DEALER_CATEGORY_SUCCESS', dispatch, responseData);
    })
    .catch((error) => {
      Dispatcher('SET_API_ERROR_RECEIVED', dispatch, true);
      Dispatcher('SET_NO_INDUSTRY',dispatch,false);
    });
    
};

export const DealersByIndustryAction = (dataParam,isPageLoad,displaydealers=true) => (dispatch) => {
if(isPageLoad){
 if(window.adobeDataLayer != undefined){
  window.adobeDataLayer.push({
    "event": "Page Loaded",
    "consent": window.OnetrustActiveGroups , 
    "page": {
     "breadCrumbs": [
     "servlet",
     'country='+dataParam["countryCode"]
     ],
     "pageName": "gdl : "+ dataParam["countryCode"]+" : "+store.getState().common.industriesObj.name ,
     "locale": dataParam["locale"],
     "siteCountry":dataParam["countryCode"],
     "siteLanguage": dataParam["locale"]
     },
     
    });
   }
}
  Dispatcher('DISABLE_GO', dispatch);
  Dispatcher('SET_API_ERROR_RECEIVED',dispatch, false);
  let inputParam = {
    dataUrl: `/dealersByIndustry`,
    method: 'post',
    requestData: dataParam,
  };
  if(displaydealers===false){
    Dispatcher('DATA_LOADED', dispatch);
  Dispatcher('DEALER_DETAILS_SUCCESS', dispatch, []);
  Dispatcher('Filter_DATA_LENGTH', dispatch, 0);
  Dispatcher('ENABLE_GO', dispatch);
return Promise.resolve([]);
}else{
  return RequestHandler1(inputParam)

    .then((responseData) => {
      if (responseData.length > 0) {
        Dispatcher('DATA_LOADED', dispatch);
        Dispatcher('SET_NO_DEALER', dispatch, false);
        Dispatcher('DEALER_DETAILS_SUCCESS', dispatch, responseData);
        Dispatcher('ENABLE_GO', dispatch);
        if(store.getState().common.industriesObj){
        window.adobeDataLayer.push({
          "event": "Dealer Searched",
          "consent": window.OnetrustActiveGroups,
          "industryName": store.getState().common.industriesObj.name,
           "dealerSearch": { 
    responseData
           },
           "searchParams": {
            "searchType": "Dealer Locator",
            "searchText": store.getState().common.industriesObj.name,
            "searchLocation":store.getState().common.selectedLocation,
            "searchDevice": store.getState().common.isMobile ? "Mobile" :"Desktop"
            }
            
          })
        }
  
      } else {
        Dispatcher('SET_NO_DEALER', dispatch, true);
      }
      return responseData;
    })
    .catch((error) => {
      Dispatcher('SET_NO_DEALER', dispatch, true);
      Dispatcher('SET_API_ERROR_RECEIVED',dispatch, true);
      Dispatcher('ENABLE_GO', dispatch);
    })
  }
};
// https://gdlapidevl.tal.deere.com/api/gdl-service/translationTerms?locale=en_US&brand=johndeere
export const DealersByIndustryAction1 = (dataParam) => (dispatch) => {
  let inputParam = {
   // base: 'https://gdlapidevl.tal.deere.com',
    dataUrl: `/api/gdl-service/translationTerms?locale=en_US&brand=johndeere`,
    method: 'get'
  };
  let inputParam2 = {
    base: `https://maps.googleapis.com`,
    dataUrl: `/maps/api/geocode/json?latlng=38.9586307,-77.35700279999999&key=AIzaSyClSN8cZpnl2922PZReSf6Igc-7ucJEDf0`,
    method: 'get',
  };

  Dispatcher('ACTION1', dispatch);

  return RequestHandler1(inputParam);
  // .then(() => {
  //   Dispatcher('ACTION2', dispatch);
  //   //return RequestHandler1(inputParam2)
  // })
  // .then((data) => {
  //   Dispatcher('ACTION3', dispatch);
  //   Dispatcher('ACTION4', dispatch, {});
  //  // return "rer";
  // });
};

export const setFilterDataLength = (filterLength) => Dispatch => {
  Dispatcher('FILTER_DATA_LENGTH', Dispatch, filterLength);
};

export const setSelectedDataParam = (selectedParam) => Dispatch => {
  Dispatcher('SET_SELECTED_DATA_PARAM', Dispatch, selectedParam);
};

export const selectALLDataParam = (dataParam) => Dispatch => {
  Dispatcher('SELECT_ALL_DATA_PARAM', Dispatch, dataParam);
};

export const setDeepLinkChange = ( isChange ) => Dispatch => {
  
  Dispatcher('DEEPLINK_CHANGE', Dispatch, isChange);
}

export const getProductGroupsAction = ({
  locale = '',
  countryCode = '',
  brand = ''
},
callback
) => (dispatch) => {
  let inputParam = {
    dataUrl: `/productGroupsByIndustry?locale=${locale}&countryCode=${countryCode}&brand=${brand}`,
    method: 'get',
  };
  Dispatcher('PRODUCT_GROUPS_EMPTY', dispatch,{});
  return RequestHandler1(inputParam)
    .then((responseData) => {
      Dispatcher('PRODUCT_GROUPS_SUCCESS', dispatch, responseData);
      if (callback) callback(responseData);
    });
};

export const setApplyFlag = (isApply) => Dispatch => {
  Dispatcher('SET_APPLY_FLAG', Dispatch, isApply);
};
export const selectEquipment = (equipment) => Dispatch => {
  Dispatcher('SELECTED_EQUIPMENT', Dispatch, equipment);
};