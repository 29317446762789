require("@babel/polyfill");
import React from 'react';
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import App from './App';
import WrapperContainer from './Wrapper'
import DealerCategoryPopup from './Shared/DealerCategoryPopup'
import RootReducer from './CombineReducers';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, browserHistory } from 'react-router'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'
import promise from 'redux-promise';
import { createLogger } from 'redux-logger';
//import FeedbackModal from './Shared/FeedbackModal/';
// import '@deere/ux.uxframe-core/dist/css/uxframe-2019.2.2.min.css';
import '@deere/ux.uxframe-core/dist/css/uxframe.min.css';
import './custom.scss';
import './styles.css';
import { HeaderContext } from '@deere/ux.uxframe-react';
import HeaderComponent from './Shared/Header';
import DealerPage from './DealerPage';



const logger = createLogger();
const composeEnhancers = global.window &&
  global.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  RootReducer, composeEnhancers(applyMiddleware(ReduxThunk, promise))
);

const history = syncHistoryWithStore(createBrowserHistory(), store)
const WrapperIndex = global.document && global.document.getElementById("root");
if(navigator.language.includes('he')) {
  document.body.style.direction= "rtl";
}else {
  document.body.style.direction= "ltr";
}
WrapperIndex ? ReactDOM.render(
  <>
  <Provider store={store}>
  <Router history={history}>
    <Switch history={history}>
      <Route exact={true}  path="/servlet/country=:country" component={WrapperContainer}/>
      <Route exact  path="/servlet" component={WrapperContainer}/>
      {/* <Route exact={true}  path="/servlet/country=:country?productGroup=:productGroup" component={DealerPage}/> */}
      <Route exact  path="/servlet/dealerlist" component={DealerPage}/>
      <Route path='*' exact={true} component={App} />
    </Switch>
    </Router>

  </Provider>,

  </>,
  WrapperIndex,
) : false;


if (global.window) {
  global.window.onResize = function () {    
    var w1 = global.document && global.document.getElementById("map_canvas");
    var leftContainer = global.document &&
      global.document.getElementById("left-container");
    let footer = global.document.querySelector(".footer").offsetHeight;
    let header = global.document.querySelector(".header").offsetHeight; 
    var windowwidth = WrapperIndex && WrapperIndex.offsetWidth || 100;
    var windowheight = footer + header;
    if (screen.width >= 1024) {
      leftContainer ?
      windowwidth = WrapperIndex &&
      leftContainer && WrapperIndex.offsetWidth - leftContainer.offsetWidth || 0
      :WrapperIndex.offsetWidth
     
    }
    windowheight = screen.height - windowheight +80;
    if (w1) {
      
      w1.style.width = windowwidth + "px";
      w1.style.height =  windowheight + "px";
    }
  };
}

export { WrapperIndex };

