export const getEnv=()=>{
  switch(global.location.hostname){
  case "localhost": 
    return "https://dealerlocatordevl.deere.com/api/gdl-service";
  case "dealerlocatordevl.deere.com":
    return "https://dealerlocatordevl.deere.com/api/gdl-service";
  case "dealerlocatorcert.deere.com":
    return "https://dealerlocatorcert.deere.com/api/gdl-service";
  case "dealerlocator.deere.com":
    return "https://dealerlocator.deere.com/api/gdl-service";
  default:
    return "http://deere.com:8080";
  }
};
