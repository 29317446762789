import React from 'react';
import Script from 'react-load-script'
import { Container, Row, Col} from "@deere/ux.uxframe-react"
import { CloseIcon } from '../icons/closeIcon';
import {
  Modal,
  Button,
} from '@deere/ux.uxframe-react';
import { store } from '../../';
import { func, shape, bool, string, array, number } from 'prop-types';
import DealerCategoryIndustries from '../DealerCategoryIndustries';
import GoogleFilters from '../../Shared/GoogleFilters/';
import Loader from '../../assets/images/gif/loaderOneGreen.gif';
import { withRouter } from 'react-router-dom';
import { MAX_MOBILE_WIDTH } from '../../Shared/Constants/AppConstants'


class DealerCategoryPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      industryId: 0,
    };
    this.inputref = React.createRef();
  }

  componentDidUpdate(pre) {
  //   if(screen.width > MAX_MOBILE_WIDTH){
  //   if(window.document.querySelector(".DealerCatForm") && window.document.getElementById('steps-uid-0-p-1') && window.document.getElementById('steps-uid-0-p-1').style.display!='block'){
  //     window.document.querySelector(".DealerCatForm").style.display="block";
  //  } 
  // }
    const {
      industries = [],
    } = this.props;

    if (pre.industries != industries) {
      this.setState({
        industryId: 0
      });
    }

  }
  handleClick = () => {
    const {
     // landing_page_action = () => { },
      dealer_category_popup_action = () => { },

    } = this.props;
    this.setState({
      industryId: 0
    });
    dealer_category_popup_action(false);
    landing_page_action(true);

  };
  handleIndustriesClick = (event, name, indId) => {
    const {
      currentTarget: {
        id = 0
      } = {},
      currentTarget = {}
    } = event;
    const {
      setDealerCategoryIndustries = () => { }
    } = this.props;
    let getId = Number(id);
    this.setState({
      industryId: getId
    });
    this.setState({
      industryName: name
    });
    currentTarget.parentElement.childNodes.forEach(e =>
      e.classList.remove("active"));
    if (currentTarget) {
      currentTarget.classList.add('active');
    }
    let industriesObj = {
      name,
      id: indId,
    };
    if(indId != 3)
      setDealerCategoryIndustries(industriesObj);
  }
  

  handleFindDealerClick = () => {

    const {
     
      common: {
        currentlatlng: {
          lat = 0,
          long = 0
        } = {},
        locale = '',
        countryCode = '',
        brand = '',
      } = {},
      dealer_category_popup_action = () => { },
      dealersListPanelDispalyAction = () => { },
      DealersByIndustryAction = () => { },
      
    } = this.props;
  

    const {
      industryId = 0,
      industryName = ""
    } = this.state;
    const dataParam = {
      "industryId": industryId,
      "countryCode": countryCode,
      "brand": brand,
      "equipmentName": "",
      "equipmentId": 0,
      "locale": locale,
      "allIndustriesSelected": false,
      "latitude": lat,
      "longitude": long,
      "radius": "0 MI"
    };  
    dealer_category_popup_action(false);
    dealersListPanelDispalyAction(true);
    //DealersByIndustryAction(dataParam);  
    let deeplinkLocale = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {}).locale;
    if(!deeplinkLocale) {
      this.props.history.push({
        pathname: `/servlet/country=${countryCode}`,
        search: `?industry=${industryId}`,
        state: { deeplinkIndustry: industryId }
      });
    } else {
      this.props.history.push({
        pathname: `/servlet/country=${countryCode}`,
        search: `?industry=${industryId}&locale=${deeplinkLocale}`,
        state: { deeplinkIndustry: industryId }
      });
    }
  }
  render() {
  
   
    const {
      gmap,
    } = this.state;
    const {
      PopUpData: {
        dealerCatPopupFlag = false
      } = {},
      common: {
        selectedLocation= '',
        locale= '',
        countryCode = ''
      },
      translate: {
        GDL_FIND_A_DEALER_NEAR_YOU = '',
        GDL_FIND_DEALERS = '',
        GDL_FIND_BY_EQUIPMENT = '',
        GDL_CHOOSE_DEALER_CATEGORY = '',
        GDL_NO_DEALER_FOUND_FOR_COUNTRY_ERROR = '',
        GDL_SEARCH_AGAIN = '',
      } = {},
      industries = [],
      history = this.props.history,
    } = this.props;
    const {
      industryId = 0,
      industryName = ""
    } = this.state;
   
    let isIndustryId = (industryId !== 0) && (industryId !== 3);

    let industriesLen = industries.length > 0;
    return (
     
      <div>
        <Modal xl show={dealerCatPopupFlag}
          className="find-a-dealer dealer-category"
          data-test="dealer-category">
          
          <Modal.Header>
            <h2 className="dealer-title">{GDL_FIND_A_DEALER_NEAR_YOU}</h2>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-10">
                <GoogleFilters {...this.props} name="google-page-level-search2" />
              </div>
            </div>
            <h3 className="choose-dealer">
              {industriesLen ? GDL_CHOOSE_DEALER_CATEGORY : null}
            </h3>
            
            {store.getState().common.apiError? <div className="error-sms">Oops!Something went wrong , please try again later</div> : (store.getState().common.noIndustry ?  <div><img src={Loader} alt="loading..." className="industryLoadingImage"/></div> :
            <div className="choose-dealer-category">
              <DealerCategoryIndustries
                industries={industries}
                handleIndustriesClick=
                  {this.handleIndustriesClick} history={history} countryCode={countryCode}
              />
            </div>)}
          </Modal.Body>
          {/* <p className="cate-equipment">
            {
              industriesLen ?
                GDL_NOT_FINDING_CATEGORY_MESSEGE :
                GDL_NO_DEALER_FOUND_FOR_COUNTRY_ERROR
            }&nbsp;
            <a
              className="handle-click"
              data-test="handle-click"
              onClick={(e) => this.handleClick(e)}
              href="javascript:void(0)">
              {industriesLen ? GDL_FIND_BY_EQUIPMENT : GDL_SEARCH_AGAIN}
            </a>
          </p> */}
          <Modal.Footer>
            <div className="row">
              <div className="col">
                <Button
                    variant="primary"
                    className={`${isIndustryId ? '' : 'disabled'}`}
                    onClick={isIndustryId ? this.handleFindDealerClick : null}
                >
                  {GDL_FIND_DEALERS}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        </div>
      
    );
  }
}

DealerCategoryPopup.propTypes = {
  dealer_category_popup_action: func,
  landing_page_action: func,
  PopUpData: shape({
    dealerCatPopupFlag: bool
  }),
  common: shape({
    currentlatlng: shape({
      lat: number,
      loan: number
    }),
    locale: string,
    countryCode: string,
    brand: string,
  }),

  translate: shape({
    GDL_FIND_A_DEALER_NEAR_YOU: string,
    GDL_FIND_DEALERS: string,
    GDL_YOUR_LOCATION: string,
  }),
  Filterdata: shape({
    DealerIndustries: array
  }),
  industries: array,
  getGeocoderBaiduMap: func,
  fetchNearestDealers: func,
  setCurrentLatLng: func,
  invalid_address_error_message_action: func,
  searchParent: func,
  dealersListPanelDispalyAction: func,
  DealersByIndustryAction: func,
  setDealerCategoryIndustries: func
};

export default withRouter(DealerCategoryPopup);
